//Libraries
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAboutInfo } from "../features/aboutSlice";
//Swiper Data
import "../assets/css/index.scss";
// import { missionDescription } from "../utils/data";
// import { aboutDescription } from "../utils/data";
// import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
//Components
import Slider from "../components/aboutSlider";
import Mountain from "../assets/images/smallMountainImage.svg";
//Utils
import { imageLink } from "../utils/timeFormatter";
const About = () => {
  const dispatch = useDispatch();
  const {
    missionSliderImages,
    aboutDescription,
    aboutSliderData,
    missionSliderData,
    missionText,
  } = useSelector((store) => store.about);

  useEffect(() => {
    dispatch(getAboutInfo());
  }, [dispatch]);

  return (
    <div className="wrapper">
      <section className="aboutMainContainer">
        <section className="aboutOverSection">
          <div className="container">
            {window.innerWidth > 550 ? (
              <>
                <div className="homeBanner">
                  <h3 className="homeBannerLabel homeBannerLabelBlue">ABOUT</h3>
                  <span className="homeBannerImageContainer">
                    <img src={Mountain} alt="Mountain" />
                  </span>
                  <h3 className="homeBannerLabel homeBannerLabelRed">GMGA</h3>
                </div>
              </>
            ) : (
              <div className="homeBannerMobile">
                <span className="homeBannerImageContainerMobile">
                  <img src={Mountain} alt="Mountain" />
                </span>
                <span className="textContainerHomeBannerMobile">
                  <h3 className="homeBannerLabelMobile homeBannerLabelBlue">
                    ABOUT
                  </h3>

                  <h3 className="homeBannerLabelMobile homeBannerLabelRed">
                    GMGA
                  </h3>
                </span>
              </div>
            )}
            <div className="aboutDescription">
              <div
                className="SliderOverText"
                dangerouslySetInnerHTML={{
                  __html: aboutDescription,
                }}
              ></div>
            </div>
            <Slider images={aboutSliderData ? aboutSliderData : []} />
          </div>
        </section>
        <div className="borderCenterd">
          <div className="border"></div>
        </div>
        <section>
          <div className="container">
            <div className="missionBannerContent">
              {/* <img src={MissionLabel} alt="mission" className="missionBanner" /> */}
              <h3 className="missionBannerLabel">MISSION</h3>
            </div>
            <p className="missionText">{missionText}</p>
            <Slider images={missionSliderData ? missionSliderData : []} />
          </div>
        </section>
      </section>
    </div>
  );
};

export default About;
