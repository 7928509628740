//Libraries
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAboutInfo } from "../../features/aboutSlice";
import { Link } from "react-router-dom";
//Swiper Data
import "../../assets/css/index.scss";
// import { missionDescription } from "../utils/data";
// import { aboutDescription } from "../utils/data";
// import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
//Components
import MainButton from "../../components/mainButton";
//Utils
import { imageLink } from "../../utils/timeFormatter";

import error from "../../assets/images/error.png";

const Fail = () => {

  return (
    <div className="wrapper">
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh'
      }}>
        <div style={{
          maxWidth: 600,
          width: '100%',
          padding: 30,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: '#00000033',
          borderRadius: 15,
          textAlign: 'center'
        }}>
          <img
            src={
              error
            }
            alt="Done"
            style={{
              width: 40,
              marginBottom: 30
            }}
          />
          <p style={{
            marginBottom: 30,
            color: '#293896',
            fontWeight: 700
          }}>Your payment has not been confirmed</p>
          <div style={{
            display: 'flex',
            justifyContent: 'center'
          }}>
            <Link to='/'>
              <MainButton
                text={'Back To Home'}
                dark={true}
                className="mobileHeaderMainButton"
              />
            </Link>
          </div>
        </div>
      </div>
    </div >
  );
};

export default Fail;
