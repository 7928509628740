//Libraries
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
//Components
import PostImageGallery from "./postImageGallery";
import Icon from "./postStatisticsIcon";
import ConfirmationModal from "./confirmationModal";
import { lang } from "../utils/lang";
//Reducers
import {
  likePost,
  unlikePost,
  deletePost,
  handlePostLikeLocally,
  handlePostUnlikeLocally,
  deletePostLocally,
  getAllPosts,
} from "../features/newsFeedSlice";
//Assets
import downloadIcon from "../assets/images/download.svg";
import likeIcon from "../assets/images/like.svg";
import commentIcon from "../assets/images/message.svg";
import arrowUpIcon from "../assets/images/arrowUp.svg";
import trashbinIcon from "../assets/images/trashbinIcon.svg";
//Utils
import {
  timeFormatter,
  dateToTimestamp,
  imageLink,
} from "../utils/timeFormatter";
import { useEffect } from "react";

const PostContent = ({ pinned, setCommentsOpen, commentsOpen, post }) => {
  const {
    id,
    user_id,
    user: {
      trans: { name, surname },
      img,
    },
    comments,
    description,
    gallery,
    // img,
    likers_ids,
    comment_count_count,
    // name,
    // surname,
    status,
    created_at,
    date,
    like,
  } = post;
  const dispatch = useDispatch();
  const { user } = useSelector((store) => store.user);
  const { posts, postsBatchNumber } = useSelector((store) => store.newsFeed);

  //There was no example of files or how they should be handled, NEEDS ATTENTION!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  const [files, setFile] = useState(null);
  const [deletePostModal, setDeletePostModal] = useState(false);
  //Handle clicks on reaction buttons
  const handleReactButton = (type) => {
    if (type === "like") {
      //------------WHEN NEW API REQUEST MADE LIKE IS ADDED LOCALLY FOR VERY INSTANT VISUALIZATION ----------//
      dispatch(
        handlePostLikeLocally({
          post_id: id,
          user_id: user?.user?.id,
        })
      );
      //--------------------------------------------------------------------------------------------------//
      dispatch(likePost({ post_id: id, userId: user?.user?.id }));
    } else if (type === "unlike") {
      //------------WHEN NEW API REQUEST MADE LIKE IS ADDED LOCALLY FOR VERY INSTANT VISUALIZATION ----------//
      dispatch(
        handlePostUnlikeLocally({
          post_id: id,
          user_id: user?.user?.id,
        })
      );
      //--------------------------------------------------------------------------------------------------//
      dispatch(unlikePost(id));
    } else if (type === "comments") {
      setCommentsOpen(!commentsOpen);
    } else if (type === "deletePost") {
      openDeletePostModal();
      // dispatch(deletePost(id));
    }
  };

  const openDeletePostModal = () => {
    setDeletePostModal(true);
  };

  const closeDeletePostModal = () => {
    setDeletePostModal(false);
  };

  const dispatchDeletePost = () => {
    dispatch(deletePost(id));
    closeDeletePostModal();
    //------------WHEN NEW API REQUEST MADE GET REQUEST IS ALSO DONE FOR INSTANT VISUALIZATION ----------//
    setTimeout(() => {
      dispatch(getAllPosts());
    }, 1000);
    //--------------------------------------------------------------------------------------------------//
  };

  //Checks if user has like given post / comment
  const checkIfUserLiked = () => {
    let likersAr = [];
    like.map((likeObj) => {
      likersAr.push(likeObj.id);
    });
    //returns false to make color of Icon component !blue.
    if (likersAr.indexOf(user?.user?.id) > -1) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <span className="postContent">
      {deletePostModal && (
        <ConfirmationModal
          question={lang.post_delete_question}
          handleRefClick={closeDeletePostModal}
          handleNo={closeDeletePostModal}
          handleYes={dispatchDeletePost}
        />
      )}

      <section className="userContainer">
        <NavLink to={`/profile/${user_id}`}>
          <img
            src={`${imageLink}${img}`}
            alt={`${name}${surname}`}
            className={
              pinned ? "pinnedPosterProfileImage" : "posterProfileImage"
            }
          />
        </NavLink>
        <div className="postInformationContainer">
          {/* <h3
            className={
              pinned ? "pinnedPosterFullName posterFullName" : "posterFullName"
            }
          > */}
          <NavLink
            to={`/profile/${user_id}`}
            className={
              pinned ? "pinnedPosterFullName posterFullName" : "posterFullName"
            }
          >
            {`${name} ${surname}`}
          </NavLink>

          {/* </h3> */}
          <p className={pinned ? "pinnedPostDate postDate" : "postDate"}>
            {/* {timeFormatter(dateToTimestamp(created_at))} */}
            {date}
          </p>
        </div>
      </section>
      <main className="postMainContent">
        <p className={pinned ? "pinnedPostText" : "postText"}>{description}</p>
        {files && (
          <div className="downloadFileContainer">
            <a
              href="/images/myw3schoolsimage.jpg"
              download="w3logo"
              className="downloadFileButton"
            >
              <img src={downloadIcon} alt="Download" />
              agi.pdf
            </a>
          </div>
        )}
        <PostImageGallery images={gallery} pinned={pinned} />
      </main>
      <section className="postStatistics">
        <div className="postReactionsContainer">
          <Icon
            icon={likeIcon}
            count={like.length}
            blue={checkIfUserLiked()}
            pinned={pinned}
            handleClick={
              checkIfUserLiked()
                ? () => handleReactButton("unlike")
                : () => handleReactButton("like")
            }
          />
          <Icon
            icon={commentIcon}
            count={comment_count_count}
            blue={commentsOpen}
            pinned={pinned}
            handleClick={() => handleReactButton("comments")}
          />
          {user?.user?.id === user_id && !pinned && (
            <Icon
              icon={trashbinIcon}
              blue={false}
              pinned={pinned}
              handleClick={() => handleReactButton("deletePost")}
            />
          )}
        </div>
        {/* <div className="reactButtonsContainer">
          {reactIcons.map((react, index) => {
            const { icon, text } = react;
            return (
              <button
                className="reactButton"
                key={index}
                onClick={() => handleReactButton(text)}
              >
                <img src={icon} alt={text} />
                {!pinned && <p>{text}</p>}
              </button>
            );
          })}
        </div> */}
      </section>
    </span>
  );
};

export default PostContent;
