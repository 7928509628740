//Libraries
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
//Reducers
import {
  likeComment,
  deleteComment,
  unlikeComment,
  getAllPosts,
  handleCommentLikeLocally,
} from "../features/newsFeedSlice";
import { lang } from "../utils/lang";
//Components
import Icon from "./postStatisticsIcon";
import CommentInput from "./commentForm";
import userProfileImage from "../assets/images/cardImg1.png";
import ConfirmationModal from "./confirmationModal";
//Assets and Images
import likeIcon from "../assets/images/like.svg";
import trashbinIcon from "../assets/images/trashbinIcon.svg";
//Utils
import {
  timeFormatter,
  dateToTimestamp,
  imageLink,
} from "../utils/timeFormatter";
const Comment = ({
  props,
  depth,
  lineHeight,
  index,
  handleReply,
  commentsAr,
}) => {
  const {
    id,
    created_at,
    comment,
    likes,
    user_id,
    users: {
      img,
      trans: { name, surname },
    },
    post_id,
    parent_comment_id,
  } = props;

  const dispatch = useDispatch();
  const { user } = useSelector((store) => store.user);
  const { posts, deleteCommentisLoading, postsBatchNumber } = useSelector(
    (store) => store.newsFeed
  );

  //Functions and states to control who liked users locally
  const [peopleWhoLikedComment, setPeopleWhoLikedComment] = useState([]);

  useEffect(() => {
    setPeopleWhoLikedComment(likes);
  }, [likes]);

  //Controls liking and unliking of comment
  const handleLike = (e) => {
    let likeData = { comment_id: id, post_id: post_id, userId: user?.user?.id };
    if (checkIfUserLiked() === false) {
      setPeopleWhoLikedComment(
        peopleWhoLikedComment.concat({ user_id: user?.user?.id })
      );

      dispatch(likeComment(likeData));
      //------------WHEN NEW API REQUEST MADE GET REQUEST IS ALSO DONE FOR INSTANT VISUALIZATION ----------//
      setTimeout(() => {
        dispatch(getAllPosts());
      }, 1000);
      //--------------------------------------------------------------------------------------------------//
    } else if (checkIfUserLiked() === true) {
      setPeopleWhoLikedComment(
        peopleWhoLikedComment.filter((liker) => liker.user_id !== user?.user?.id)
      );
      dispatch(unlikeComment(likeData.comment_id));
      //------------WHEN NEW API REQUEST MADE GET REQUEST IS ALSO DONE FOR INSTANT VISUALIZATION ----------//
      setTimeout(() => {
        dispatch(getAllPosts());
      }, 1000);
      //--------------------------------------------------------------------------------------------------//
    }
  };

  const checkIfUserLiked = () => {
    let likersAr = [];
    peopleWhoLikedComment.map((person) => likersAr.push(person.user_id));
    if (likersAr.indexOf(user?.user?.id) > -1) {
      return true;
    } else {
      return false;
    }
  };

  //Delete Comment Functions
  const [deleteCommentModal, setDeleteCommentModal] = useState(false);

  const openDeleteCommentModal = () => {
    setDeleteCommentModal(true);
  };

  const closeDeleteCommentModal = () => {
    setDeleteCommentModal(false);
  };
  const handleDeleteComment = () => {
    dispatch(deleteComment(id));
    //------------WHEN NEW API REQUEST MADE GET REQUEST IS ALSO DONE FOR INSTANT VISUALIZATION ----------//
    setTimeout(() => {
      dispatch(getAllPosts());
    }, 1000);
    //--------------------------------------------------------------------------------------------------//
  };

  useEffect(() => {
    if (!deleteCommentisLoading) {
      closeDeleteCommentModal();
    }
  }, [deleteCommentisLoading]);

  //This is component for the single comment, and if the comment is last in array, vertical line connecting it to parent comment is rendered, the style in div is calculation of exact location of this line on dom
  return (
    <section className="commentContainer">
      {deleteCommentModal && (
        <ConfirmationModal
          question={lang.comment_delete_question}
          handleRefClick={closeDeleteCommentModal}
          handleNo={closeDeleteCommentModal}
          handleYes={handleDeleteComment}
        />
      )}

      <div className="commenterProfileImageContainer">
        {depth > 0 && <div className="cornerLine" />}
        {depth > 0 && index === commentsAr.length - 1 && (
          <div
            className="verticalLine"
            style={{
              height: `${lineHeight}px`,
              marginTop: `-${lineHeight}px`,
            }}
          />
        )}

        <img src={`${imageLink}${img}`} alt="user" />
      </div>
      <main className="commentMainContainer">
        <div className="commentContent">
          <h3 className="commenterFullName">
            {name} {surname}
          </h3>
          <p className="commentText">{comment}</p>
        </div>
        {/* this is div which contains all possible actions which can be taken on the given comment */}
        <div className="commentReactionsContainer">
          {checkIfUserLiked() ? (
            <button
              className="commentLikeButton commentLikeButtonLiked"
              onClick={handleLike}
            >
              {lang.like}
            </button>
          ) : (
            <button
              className="commentLikeButton commentLikeButtonNotLiked"
              onClick={handleLike}
            >
              {lang.like}
            </button>
          )}
          <button className="commentReplyButton" onClick={handleReply}>
            {lang.reply}
          </button>
          <p className="commentDateLabel">
            {timeFormatter(dateToTimestamp(created_at)).toUpperCase()}
          </p>
          {peopleWhoLikedComment && peopleWhoLikedComment.length > 0 && (
            <Icon
              icon={likeIcon}
              count={peopleWhoLikedComment.length}
              blue={true}
            />
          )}
          {user?.user?.id === user_id && (
            <Icon
              icon={trashbinIcon}
              blue={false}
              handleClick={openDeleteCommentModal}
            />
          )}
        </div>
      </main>
    </section>
  );
};

export default Comment;
