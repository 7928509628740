import HomeCards from "../components/homeCards";
import homeBanner from "../assets/images/homeMainBanner.png";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
//Reducers
import { getGuidesInfo } from "../features/homeSlice";
//Assets
import Mountain from "../assets/images/smallMountainImage.svg";
import Loading from "../components/loading";
const Home = () => {
  const dispatch = useDispatch();
  const { homeMainDescription, guidesIsLoading, guidesList } = useSelector(
    (store) => store.home
  );

  useEffect(() => {
    dispatch(getGuidesInfo());
  }, [dispatch]);

  return (
    <section className="mainHomeContainer">
      {!guidesList ? (
        <div className="loadingContainer">
          <Loading className={"loading"} />
        </div>
      ) : (
        <>
          <div className="container">
            {window.innerWidth > 550 ? (
              <>
                <div className="homeBanner">
                  <h3 className="homeBannerLabel homeBannerLabelBlue">ABOUT</h3>
                  <span className="homeBannerImageContainer">
                    <img src={Mountain} alt="Mountain" />
                  </span>
                  <h3 className="homeBannerLabel homeBannerLabelRed">GMGA</h3>
                </div>
              </>
            ) : (
              <div className="homeBannerMobile">
                <span className="homeBannerImageContainerMobile">
                  <img src={Mountain} alt="Mountain" />
                </span>
                <span className="textContainerHomeBannerMobile">
                  <h3 className="homeBannerLabelMobile homeBannerLabelBlue">
                    ABOUT
                  </h3>

                  <h3 className="homeBannerLabelMobile homeBannerLabelRed">
                    GMGA
                  </h3>
                </span>
              </div>
            )}

            <p className="homeShortDescription">{homeMainDescription}</p>
            <div className="border"></div>
            <HomeCards />
          </div>
        </>
      )}
    </section>
  );
};

export default Home;
