/** @format */

//Libraries
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Modal, Button } from "react-bootstrap";

//Components
import PhotoEditing from "../components/photoEditingConmponent";
import ImageGalleryModal from "../components/imageGalleryModal";
import MainButton from "../components/mainButton";
import Loading from "../components/loading";
import UserCaleldar from "../components/userCalendar";
import { ToastContainer } from "react-toastify";

//Assets
import InstagramIcon from "../assets/images/instagramIcon.svg";
import MailIcon from "../assets/images/mailIcon.svg";
import LanguagesIcon from "../assets/images/languageIcon.svg";
import PhoneIcon from "../assets/images/phoneIcon.svg";
import WhatsappIcon from "../assets/images/whatsappIcon.svg";
import editProfile from "../assets/images/editProfile.svg";
import cameraIcon from "../assets/images/cameraIcon.svg";

//Reducers
import { setModalOpen } from "../features/imageGallerySlice";
import { getGuidesInfo } from "../features/homeSlice";
import {
  getSingleUser,
  saveProfileInfo,
  addCoverPhoto,
  addCoverPhotoStringed,
  addProfilePhoto,
  addProfilePhotoStringed,
  getUserProfilePhoto,
  addGalleryPhotos,
} from "../features/singleProfileSlice";
//Utils
import { imageLink } from "../utils/timeFormatter";
import { lang } from "../utils/lang";

import Calendar from "rc-year-calendar";

const EditProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { galleryModalOpen } = useSelector((store) => store.imageGallery);
  const { user } = useSelector((store) => store.user);
  const {
    singleUser,
    uploadedCoverPhoto,
    coverPhotoIsUploading,
    uploadedProfilePhoto,
    profilePhotoIsUploading,
    uploadedGallery,
    addGalleryPhotosLoading,
    addGalleryPhotosStringed,
  } = useSelector((store) => store.singleProfile);
  const { activities } = useSelector((store) => store.home);

  useEffect(() => {
    dispatch(getGuidesInfo(1));
  }, [dispatch]);

  const [dataForSubmission, setDataForSubmission] = useState(null);

  const [userData, setUserData] = useState({
    name: ``,
    surname: "",
    full_name: "",
    img_cover: null,
    img: null,
    tegs: null,
    whatsapp: "",
    instagram: "",
    email: "",
    phone: "",
    language: "",
    description: "",
  });

  useEffect(() => {
    if (singleUser) {
      const {
        email,
        img,
        img_cover,
        instagram,
        language,
        phone,
        tegs,
        whatsapp,
        trans: { description, name, surname },
      } = singleUser;
      setUserData({
        name: name || "",
        surname: surname || "",
        full_name: `${name} ${surname}` || "",
        img_cover: img_cover,
        img: img,
        tegs: setTegsAr(tegs),
        whatsapp: whatsapp || "",
        instagram: instagram || "",
        email: email || "",
        phone: phone || "",
        language: language || "",
        description: description || "",
      });
    }
  }, [singleUser]);

  useEffect(() => {
    dispatch(getSingleUser(user?.user?.id));
  }, [dispatch]);

  //Function to handle name editing, and open input to edit name on click
  const [editingName, setEditingName] = useState(false);

  const setNameEditingState = () => {
    setEditingName(!editingName);
  };
  const nameEditingInput = useRef();

  const closeNameEditing = (e) => {
    if (
      nameEditingInput.current &&
      !nameEditingInput.current.contains(e.target)
    ) {
      setEditingName(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", closeNameEditing, true);
    return () => {
      document.removeEventListener("click", closeNameEditing, true);
    };
  }, []);

  //Function to view photo in full screen using modal
  const viewPhoto = (photo) => {
    const modalData = {
      scrollPosition: window.pageYOffset,
      galleryModalOpen: true,
      slideIndex: 1,
      images: [photo],
    };
    dispatch(setModalOpen(modalData));
  };

  //Function to handle all user info except exceiptions
  const handleProfileInfo = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (e.target.name === "name") {
      let nameSurnameAr = e.target.value.split(" ");
      let nameValue = nameSurnameAr[0];
      let surnameValue = nameSurnameAr.slice(1).join(" ");
      setUserData({
        ...userData,
        name: nameValue,
        surname: surnameValue,
        full_name: value,
      });
      setDataForSubmission({
        ...dataForSubmission,
        name: nameValue,
        surname: surnameValue,
      });
    } else {
      setUserData({ ...userData, [name]: value });
      setDataForSubmission({ ...dataForSubmission, [name]: value });
    }
  };

  //Cover Photo Upload Functions
  const handleCoverUpload = (event) => {
    for (let i = 0; i < event.target.files.length; i++) {
      const formData = new FormData();
      formData.append("img", event.target.files[i]);
      dispatch(addCoverPhoto(formData));
    }
  };

  useEffect(() => {
    if (uploadedCoverPhoto.length > 0) {
      dispatch(addCoverPhotoStringed({ img_cover: uploadedCoverPhoto }));
    }
  }, [uploadedCoverPhoto, dispatch]);

  //Profile Photo Upload Functions
  const handleProfileImageUpload = (event) => {
    for (let i = 0; i < event.target.files.length; i++) {
      const formData = new FormData();
      formData.append("img", event.target.files[i]);
      dispatch(addProfilePhoto(formData));
    }
  };

  useEffect(() => {
    if (uploadedProfilePhoto.length > 0) {
      dispatch(addProfilePhotoStringed({ img: uploadedProfilePhoto }));
    }

    dispatch(getUserProfilePhoto());
  }, [uploadedProfilePhoto, dispatch]);

  //Gallery Upload Functions

  const uploadPhotosToGallery = (event) => {
    for (let i = 0; i < event.target.files.length; i++) {
      const formData = new FormData();
      // console.log(event.target.files[i]);
      formData.append("img", event.target.files[i]);
      dispatch(addGalleryPhotos(formData));
    }
  };

  useEffect(() => {
    const formData = new FormData();
    formData.append("imgs[]", uploadedGallery);
    console.log(uploadedGallery);
    setDataForSubmission({ ...dataForSubmission, imgs: uploadedGallery });
    setUserData({ ...userData, imgs: uploadedGallery });
  }, [uploadedGallery]);

  //Function to handle Skills which user has
  const setTegsAr = (tegs) => {
    let tegAr = [];
    if (tegs) {
      for (const teg of tegs) {
        tegAr.push(teg.id);
      }
    }
    return tegAr;
  };

  const handleSkills = (id) => {
    let filteredTegs = [];
    if (userData.tegs.find((teg) => teg === id)) {
      filteredTegs = userData.tegs.filter((teg) => teg !== id);
      setUserData({ ...userData, tegs: filteredTegs });
      setDataForSubmission({ ...dataForSubmission, tegs: filteredTegs });
    } else {
      setUserData({ ...userData, tegs: userData.tegs.concat(id) });
      setDataForSubmission({
        ...dataForSubmission,
        tegs: userData.tegs.concat(id),
      });
    }
  };

  //Function to save whole Form
  const dispatchSaveProfileInfo = (e) => {
    e.preventDefault();
    console.log(dataForSubmission);
    dispatch(saveProfileInfo(dataForSubmission));
    setTimeout(() => {
      navigate(`/profile/${singleUser.id}`);
    }, 1000);
  };

  // new Update
  const openPopupScheduled = () => {
    document.getElementById("popupContentScheduled").classList.toggle("d-flex");
  };

  const openPopupRequestsList = () => {
    document
      .getElementById("popupContentRequestsList")
      .classList.toggle("d-flex");
  };

  const currentYear = new Date().getFullYear();

  const [dataSource, setDataSource] = useState([]);
  const [currentEvent, setCurrentEvent] = useState(null);

  const saveCurrentEvent = () => {
    if (currentEvent && currentEvent.id === undefined) {
      // Add event
      currentEvent.id = Math.max(...dataSource.map((evt) => evt.id)) + 1;
      setDataSource([...dataSource, currentEvent]);
    } else {
      // Update event
      const updatedDataSource = dataSource.map((evt) =>
        evt.id === currentEvent.id ? currentEvent : evt
      );
      setDataSource(updatedDataSource);
    }
    setCurrentEvent(null);
  };

  return (
    <>
      {galleryModalOpen ? (
        <ImageGalleryModal />
      ) : (
        <span className="editProfilePage">
          <ToastContainer />
          <section className="editProfileMainUserBanner">
            <div className="editCoverPhotoContainer">
              {coverPhotoIsUploading ? (
                <Loading className={"loading loading-center"} />
              ) : (
                <>
                  <img
                    className="editProfileCoverPhoto"
                    src={`${imageLink}${userData.img_cover}`}
                    alt="Profile Cover"
                    onClick={() => viewPhoto(userData.img_cover)}
                  />
                  <div className="editProfileCoverIcon">
                    {/* <PhotoEditing
                      viewProfilePicture={lang.view_cover_photo}
                      updateProfilePicture={lang.update_cover_photo}
                      handleChange={handleCoverUpload}
                      data={userData.img_cover}
                      name={"img_cover"}
                      viewPhoto={viewPhoto}
                    /> */}
                    <div className="photoOptionsContent">
                      <div className="photoProfileCenter">
                        <label className="photoprofileContent">
                          <img
                            style={{ pointerEvents: "none" }}
                            src={cameraIcon}
                            alt="Camera Icon"
                          />
                          <input
                            type="file"
                            onChange={handleCoverUpload}
                            name={"img_cover"}
                            accept="image/jpeg, image/png"
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="editProfilePhotoAndNameContainer">
              <div className="editProfilePhotoContainer">
                {profilePhotoIsUploading ? (
                  <span className="loadingProfilePictureContainer">
                    <Loading className={"loading loading-center"} />
                  </span>
                ) : (
                  <>
                    <span
                      className="editProfileImage"
                      onClick={() => viewPhoto(userData.img)}
                    >
                      <img
                        src={`${imageLink}${userData.img}`}
                        alt="profileImg"
                      />
                    </span>
                    <div className="editProfilePictureIcon">
                      {/* <PhotoEditing
                        viewProfilePicture={lang.view_profile_photo}
                        updateProfilePicture={lang.update_profile_photo}
                        handleChange={handleProfileImageUpload}
                        data={userData.img}
                        name={"profilePicture"}
                        viewPhoto={viewPhoto}
                      /> */}
                      <div className="photoOptionsContent">
                        <div className="photoProfileCenter">
                          <label className="photoprofileContent">
                            <img
                              style={{ pointerEvents: "none" }}
                              src={cameraIcon}
                              alt="Camera Icon"
                            />
                            <input
                              type="file"
                              onChange={handleProfileImageUpload}
                              name={"profilePicture"}
                              accept="image/jpeg, image/png"
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="editNameContainer" ref={nameEditingInput}>
                {editingName ? (
                  <div className="editUpdateContent">
                    <input
                      type="text"
                      value={userData.full_name}
                      onChange={handleProfileInfo}
                      name="name"
                    />
                  </div>
                ) : (
                  <h2 className="editProfileUserName"> {userData.full_name}</h2>
                )}

                <button
                  className="editProfileBtn"
                  onClick={setNameEditingState}
                >
                  <img src={editProfile} alt="editProfile" />
                </button>
              </div>
              <div className="profilesCheduledtime">
                {/* <MainButton
                  className="headerMainButton mr-2"
                  text={"scheduled time"}
                  handleClick={openPopupScheduled}
                /> */}
                <MainButton
                  className="headerMainButton"
                  text={"Scheduled time"}
                  handleClick={openPopupRequestsList}
                />
              </div>
            </div>
          </section>
          <section className="editProfileUserSettings">
            <h4 className="editProfileActivitiesLabel">{lang.activities}</h4>
            <div className="selectableSkillsContainer">
              {/* Function to check if user provides given activity, and if user does, activity is highlighted */}
              {userData.tegs &&
                activities.map((skill, index) => {
                  const {
                    id,
                    trans: { title },
                  } = skill;
                  if (userData.tegs.find((teg) => teg === id)) {
                    return (
                      <MainButton
                        key={index}
                        text={title}
                        className="selectableSkill"
                        selectable={true}
                        dark={true}
                        handleClick={() => handleSkills(id)}
                      />
                    );
                  } else {
                    return (
                      <MainButton
                        key={index}
                        text={title}
                        className="selectableSkill"
                        selectable={true}
                        dark={false}
                        handleClick={() => handleSkills(id)}
                      />
                    );
                  }
                })}
            </div>
            <section className="editFormContainer">
              <div className="mainTextInputsContainer">
                <div className="contactsAndLanguagesInputsContainer">
                  <div className="singleInputsContainer">
                    <label>
                      <h4 className="contactInfoTitle">{lang.contact_info}</h4>
                      <div className="inputIconContainer">
                        <div className="inputIcon">
                          <img src={WhatsappIcon} alt="whatsApp" />
                        </div>
                        <input
                          id="whatsapp"
                          type="text"
                          autoComplete="off"
                          value={userData.whatsapp}
                          className="userInfoInput"
                          onChange={handleProfileInfo}
                          name="whatsapp"
                          placeholder="Whatsapp"
                        />
                      </div>
                      <div className="inputIconContainer">
                        <div className="inputIcon">
                          <img src={InstagramIcon} alt="instagram" />
                        </div>
                        <input
                          id="instagram"
                          type="text"
                          autoComplete="off"
                          value={userData.instagram}
                          className="userInfoInput"
                          onChange={handleProfileInfo}
                          name="instagram"
                          placeholder="Instagram"
                        />
                      </div>
                      <div className="inputIconContainer">
                        <div className="inputIcon">
                          <img src={MailIcon} alt="mail" />
                        </div>
                        <input
                          id="email"
                          type="email"
                          autoComplete="off"
                          value={userData.email}
                          className="userInfoInput"
                          onChange={handleProfileInfo}
                          name="email"
                          placeholder="Mail"
                        />
                      </div>
                      <div className="inputIconContainer">
                        <div className="inputIcon">
                          <img src={PhoneIcon} alt="phone" />
                        </div>
                        <input
                          id="phone"
                          type="text"
                          autoComplete="off"
                          value={userData.phone}
                          className="userInfoInput"
                          onChange={handleProfileInfo}
                          name="phone"
                          placeholder="Phone"
                        />
                      </div>
                    </label>
                  </div>

                  <div className="singleInputsContainer">
                    <label>
                      <h4 className="text contactInfoTitle">
                        {lang.languages}
                      </h4>
                      <div className="inputIconContainer">
                        <div className="inputIcon">
                          <img src={LanguagesIcon} alt="whatsApp" />
                        </div>
                        <input
                          type="text"
                          autoComplete="off"
                          value={userData.language}
                          className="userInfoInput"
                          onChange={handleProfileInfo}
                          name="language"
                          placeholder="Languages///"
                        />
                      </div>
                    </label>
                  </div>
                </div>

                <div className="galleryUploadContainer">
                  {addGalleryPhotosLoading ? (
                    <>
                      <Loading className={"loading loading-center"} />
                    </>
                  ) : (
                    <>
                      <label className="uploadGalleryButton">
                        <input
                          type="file"
                          multiple
                          onChange={uploadPhotosToGallery}
                          accept="image/jpeg, image/png"
                        />
                        <span className="uploadGalleryInformation">
                          add photos to gallery{" "}
                          {uploadedGallery.length > 0 && (
                            <p className="photosSelected">
                              {uploadedGallery.length} selected - save changes
                              to add photos
                            </p>
                          )}
                        </span>
                        <img src={cameraIcon} alt="camera" />
                      </label>
                    </>
                  )}
                </div>
                <div className="textAreaColumn">
                  <label>
                    <h4 className="aboutYouLabel">{lang.about_you}</h4>
                    <textarea
                      id="description"
                      type="text"
                      autoComplete="off"
                      name="description"
                      value={userData.description}
                      onChange={handleProfileInfo}
                      className="aboutUserTextArea"
                    ></textarea>
                  </label>
                </div>

                <div className="editSubmitButtonContainer">
                  <button
                    className="editSubmitButton"
                    onClick={dispatchSaveProfileInfo}
                  >
                    {lang.save_changes}
                  </button>
                </div>
              </div>
            </section>
          </section>
          <section>
            <div className="popup-content" id="popupContentScheduled">
              <div className="popup-container scroll">
                <button
                  className="popup-colse-btn"
                  onClick={openPopupScheduled}
                >
                  <svg
                    width="14"
                    height="15"
                    viewBox="0 0 14 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.25977 0.0390625H3.37891L7.00195 5.45898L10.6445 0.0390625H12.7637L8.07617 7.04102L13.0762 14.6191H10.957L6.85547 8.41797L2.72461 14.6191H0.595703L5.80078 6.83594L1.25977 0.0390625Z"
                      fill="white"
                    />
                  </svg>
                </button>
                <div className="scroll">
                  <div>
                    <h2 className="popup-title">Your Order</h2>
                    <div style={{ marginBottom: 50 }}>
                      <div className="popup-table mb-3">
                        <h5 className="table-item-title">
                          Customer: Julia Roberts
                        </h5>
                        <div className="table-item">
                          <p className="left">WhatsApp phone number</p>
                          <p className="right">+995 000 00 00 00</p>
                        </div>
                        <div className="table-item">
                          <p className="left">Email</p>
                          <p className="right">Gmail@Gmail.com</p>
                        </div>
                        <div className="table-item">
                          <p className="left">Reserved seat:</p>
                          <p className="right">Bakuriani</p>
                        </div>
                        <div className="table-item">
                          <p className="left">Сhosen date:</p>
                          <p className="right">FRI, APR 28.2023</p>
                        </div>
                        <div className="table-item last-table-item">
                          <p className="left">Subtotal</p>
                          <p className="right">$50</p>
                        </div>
                      </div>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <div style={{ marginRight: 15 }}>
                          <MainButton className="popup-btn" text={"refusal"} />
                        </div>
                        <MainButton
                          className="popup-btn"
                          dark={true}
                          text={"Done"}
                        />
                      </div>
                    </div>
                    <div style={{ marginBottom: 50 }}>
                      <div className="popup-table mb-3">
                        <h5 className="table-item-title">
                          Customer: Julia Roberts
                        </h5>
                        <div className="table-item">
                          <p className="left">WhatsApp phone number</p>
                          <p className="right">+995 000 00 00 00</p>
                        </div>
                        <div className="table-item">
                          <p className="left">Email</p>
                          <p className="right">Gmail@Gmail.com</p>
                        </div>
                        <div className="table-item">
                          <p className="left">Reserved seat:</p>
                          <p className="right">Bakuriani</p>
                        </div>
                        <div className="table-item">
                          <p className="left">Сhosen date:</p>
                          <p className="right">FRI, APR 28.2023</p>
                        </div>
                        <div className="table-item last-table-item">
                          <p className="left">Subtotal</p>
                          <p className="right">$50</p>
                        </div>
                      </div>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <div style={{ marginRight: 15 }}>
                          <MainButton className="popup-btn" text={"refusal"} />
                        </div>
                        <MainButton
                          className="popup-btn"
                          dark={true}
                          text={"Done"}
                        />
                      </div>
                    </div>
                    <div style={{ marginBottom: 50 }}>
                      <div className="popup-table mb-3">
                        <h5 className="table-item-title">
                          Customer: Julia Roberts
                        </h5>
                        <div className="table-item">
                          <p className="left">WhatsApp phone number</p>
                          <p className="right">+995 000 00 00 00</p>
                        </div>
                        <div className="table-item">
                          <p className="left">Email</p>
                          <p className="right">Gmail@Gmail.com</p>
                        </div>
                        <div className="table-item">
                          <p className="left">Reserved seat:</p>
                          <p className="right">Bakuriani</p>
                        </div>
                        <div className="table-item">
                          <p className="left">Сhosen date:</p>
                          <p className="right">FRI, APR 28.2023</p>
                        </div>
                        <div className="table-item last-table-item">
                          <p className="left">Subtotal</p>
                          <p className="right">$50</p>
                        </div>
                      </div>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <div style={{ marginRight: 15 }}>
                          <MainButton className="popup-btn" text={"refusal"} />
                        </div>
                        <MainButton
                          className="popup-btn"
                          dark={true}
                          text={"Done"}
                        />
                      </div>
                    </div>
                    <div style={{ marginBottom: 50 }}>
                      <div className="popup-table mb-3">
                        <h5 className="table-item-title">
                          Customer: Julia Roberts
                        </h5>
                        <div className="table-item">
                          <p className="left">WhatsApp phone number</p>
                          <p className="right">+995 000 00 00 00</p>
                        </div>
                        <div className="table-item">
                          <p className="left">Email</p>
                          <p className="right">Gmail@Gmail.com</p>
                        </div>
                        <div className="table-item">
                          <p className="left">Reserved seat:</p>
                          <p className="right">Bakuriani</p>
                        </div>
                        <div className="table-item">
                          <p className="left">Сhosen date:</p>
                          <p className="right">FRI, APR 28.2023</p>
                        </div>
                        <div className="table-item last-table-item">
                          <p className="left">Subtotal</p>
                          <p className="right">$50</p>
                        </div>
                      </div>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <div style={{ marginRight: 15 }}>
                          <MainButton className="popup-btn" text={"refusal"} />
                        </div>
                        <MainButton
                          className="popup-btn"
                          dark={true}
                          text={"Done"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="popup-overlay"></div>
            </div>
            <div className="popup-content" id="popupContentRequestsList">
              <div className="popup-container scroll">
                <button
                  className="popup-colse-btn"
                  onClick={openPopupRequestsList}
                >
                  <svg
                    width="14"
                    height="15"
                    viewBox="0 0 14 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.25977 0.0390625H3.37891L7.00195 5.45898L10.6445 0.0390625H12.7637L8.07617 7.04102L13.0762 14.6191H10.957L6.85547 8.41797L2.72461 14.6191H0.595703L5.80078 6.83594L1.25977 0.0390625Z"
                      fill="white"
                    />
                  </svg>
                </button>
                <div className="scroll">
                  <div>
                    <h2 className="popup-title">scheduled time</h2>
                    <div>
                      <UserCaleldar {...singleUser} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="popup-overlay"></div>
            </div>
          </section>
        </span>
      )}
    </>
  );
};

export default EditProfile;
