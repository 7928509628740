import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import Calendar from "rc-year-calendar";
import { useDispatch, useSelector } from "react-redux";
import { saveCalendar } from "../features/userSlice";

const currentYear = new Date().getFullYear();

const UserCalendar = (user) => {
  const [dataSource, setDataSource] = useState([]);
  const [currentEvent, setCurrentEvent] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setDataSource(
      user?.calendars?.map((item) => ({
        id: item.id,
        name: item.name,
        location: item.location,
        startDate: new Date(item.start_date),
        endDate: new Date(item.end_date),
      }))
    );
  }, [user]);

  const saveCurrentEvent = () => {
    if (currentEvent.id === undefined) {
      // Add event
      let newId;
      if (dataSource && dataSource.length > 0) {
        newId = Math.max(...dataSource.map((evt) => evt.id)) + 1;
      } else {
        newId = 1; // or any other default value you prefer
      }

      const updatedDataSource = [
        ...dataSource,
        { name: newId, location: "", ...currentEvent, id: newId },
      ];
      setDataSource(updatedDataSource);
      setCurrentEvent(null);

      dispatch(saveCalendar({ data: updatedDataSource }));
    } else {
      // Update event
      const updatedDataSource = [...dataSource];
      const index = updatedDataSource.findIndex(
        (evt) => evt.id === currentEvent.id
      );
      updatedDataSource[index] = currentEvent;
      setDataSource(updatedDataSource);
      setCurrentEvent(null);

      dispatch(saveCalendar({ data: updatedDataSource }));
    }
  };

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <Calendar
        enableRangeSelection={true}
        enableContextMenu={true}
        contextMenuItems={[
          {
            text: "Update",
            click: (evt) => setCurrentEvent(evt),
          },
          {
            text: "Delete",
            click: (evt) =>
              setDataSource(dataSource.filter((item) => item.id != evt.id)),
          },
        ]}
        onRangeSelected={(e) => {
          setCurrentEvent({ startDate: e.startDate, endDate: e.endDate });
        }}
        dataSource={dataSource}
      />
      <Modal show={currentEvent} onHide={() => setCurrentEvent(null)}>
        {currentEvent && (
          <div>
            <Modal.Header closeButton>
              <Modal.Title>
                {currentEvent.id !== undefined ? "Update event" : "Add event"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form className="form-horizontal">
                <div className="form-group row  mb-3">
                  <label
                    htmlFor="event-name"
                    className="col-sm-2 control-label"
                  >
                    Name
                  </label>
                  <div className="col-sm-10">
                    <input
                      id="event-name"
                      type="text"
                      className="form-control"
                      value={currentEvent.name}
                      onChange={(e) =>
                        setCurrentEvent({
                          ...currentEvent,
                          name: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row  mb-3">
                  <label
                    htmlFor="event-location"
                    className="col-sm-2 control-label"
                  >
                    Location
                  </label>
                  <div className="col-sm-10">
                    <input
                      id="event-location"
                      type="text"
                      className="form-control"
                      value={currentEvent.location}
                      onChange={(e) =>
                        setCurrentEvent({
                          ...currentEvent,
                          location: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="min-date" className="col-sm-2 control-label">
                    Dates
                  </label>
                  <div className="col-sm-10">
                    <div className="input-group input-daterange">
                      <input
                        id="min-date"
                        type="date"
                        className="form-control"
                        value={currentEvent.startDate
                          .toISOString()
                          .substr(0, 10)}
                        onChange={(e) =>
                          setCurrentEvent({
                            ...currentEvent,
                            startDate: e.target.valueAsDate,
                          })
                        }
                      />
                      <input
                        type="date"
                        className="form-control"
                        value={currentEvent.endDate.toISOString().substr(0, 10)}
                        onChange={(e) =>
                          setCurrentEvent({
                            ...currentEvent,
                            endDate: e.target.valueAsDate,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setCurrentEvent(null)}>
                Close
              </Button>
              <Button variant="primary" onClick={() => saveCurrentEvent()}>
                Save event
              </Button>
            </Modal.Footer>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default UserCalendar;
