import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  changePassword,
  setNewPasswordsMathingError,
} from "../features/userSlice";

import { lang } from "../utils/lang";
import Loading from "./loading";

import MainButton from "./mainButton";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const {
    selectedLanguage,
    passwordChangeNotification,
    user_id,
    passwordResetIsLoading,
  } = useSelector((store) => store.user);

  const [passwordChangeData, setPasswordChangeData] = useState({
    old_password: "",
    new_password: "",
    password_confirm: "",
  });

  // useEffect(() => {
  //   console.log(passwordResetIsLoading);
  // }, [passwordResetIsLoading]);

  useEffect(() => {
    dispatch(setNewPasswordsMathingError(""));
  }, []);

  const handlePassWordChangeData = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setPasswordChangeData({
      ...passwordChangeData,
      [name]: value,
    });
  };

  const submitPasswordChange = (e) => {
    e.preventDefault();
    if (
      !passwordChangeData.new_password ||
      !passwordChangeData.password_confirm ||
      !passwordChangeData.old_password
    ) {
      dispatch(setNewPasswordsMathingError(lang.please_fill_in_all_fields));
      return;
    } else if (
      passwordChangeData.new_password !== passwordChangeData.password_confirm
    ) {
      dispatch(setNewPasswordsMathingError(lang.passwords_dont_match));
      return;
    } else {
      dispatch(changePassword(passwordChangeData));
    }
  };

  useEffect(() => {
    if (passwordChangeNotification.success === true) {
      setPasswordChangeData({
        old_password: "",
        new_password: "",
        password_confirm: "",
      });
    }
  }, [passwordChangeNotification]);

  return (
    <section className="changePasswordContainer">
      <h3
        className="changePasswordHeader"
        style={
          selectedLanguage === "ka"
            ? { fontSize: "35px" }
            : { fontSize: "auto" }
        }
      >
        {lang.change_password}
      </h3>
      <form className="changePasswordForm">
        <label className="changePasswordLabel">
          {lang.current_password}
          <input
            type="password"
            name="old_password"
            value={passwordChangeData.old_password}
            className="changePasswordInput"
            onChange={handlePassWordChangeData}
          />
        </label>
        <label className="changePasswordLabel">
          {lang.new_password}
          <input
            type="password"
            name="new_password"
            value={passwordChangeData.new_password}
            className="changePasswordInput"
            onChange={handlePassWordChangeData}
          />
        </label>
        <label className="changePasswordLabel">
          {lang.new_password}
          <input
            type="password"
            name="password_confirm"
            value={passwordChangeData.password_confirm}
            className="changePasswordInput"
            onChange={handlePassWordChangeData}
          />
        </label>
        {passwordChangeNotification.text && (
          <p
            className={
              passwordChangeNotification.success
                ? "passwordChangeNotification passwordChangeSuccess"
                : "passwordChangeNotification passwordChangeError"
            }
          >
            {passwordChangeNotification.text}
          </p>
        )}
        <div className="changePasswordSubmitContainer">
          {passwordResetIsLoading ? (
            <>
              <Loading className={"loading_small"} />
            </>
          ) : (
            <MainButton
              dark={true}
              fontWeight={"700"}
              fontSize={"13px"}
              className={"resetPasswordButton"}
              text={lang.change_password}
              handleClick={submitPasswordChange}
            />
          )}
        </div>
      </form>
    </section>
  );
};
export default ChangePassword;
