import LocalizedStrings from "react-localization";
import { useEffect } from "react";
import { useSelector } from "react-redux";
export let lang = new LocalizedStrings({
  en: {
    //Forum Page Langs
    announcement: "ANNOUNCEMETS",
    pinned_posts: "PINNED POSTS",
    post: "Post",
    add_photos: "Add Photos",
    add_files: "Add Files",
    selected: "Selected",
    write_post: "Write a Post",
    search_forum: "Search In Forum",
    all: "All",
    new: "New",
    top: "Top",
    hot: "Hot",
    like: "Like",
    reply: "Reply",
    write_comment: "Write a comment",
    write_reply: "Write a reply",
    comment_delete_question: "Are you sure you want to delete this comment?",
    post_delete_question: "Are you sure you want to delete this post?",
    yes: "Yes",
    no: "No",
    //GMGA Home Page Langs
    filter_by_category: "Filter By Category:",
    search_by_name: "Search By Name:",
    no_guides_found: "No Guides Found...",
    load_more_guides: "Load More Guides",
    //Settings
    settings: "Settings",
    change_password: "Change Password",
    current_password: "Current Password:",
    new_password: "New Password:",
    confirm_new_password: "Confirm New Password:",
    please_fill_in_all_fields: "Please fill in all fields",
    passwords_dont_match: "Passwords do not match",
    incorrect_password: "Incorrect Password",
    password_changed: "Password Has Been Reset!",
    //Profile Page
    activities: "Activities",
    about: "About",
    posts: "Posts",
    //Edit Profile
    contact_info: "Contact Info",
    languages: "Languages",
    about_you: "About You",
    save_changes: "Save Changes",
    view_cover_photo: "View Cover Photo",
    update_cover_photo: "Update Cover Photo",
    view_profile_photo: "View Profile Photo",
    update_profile_photo: "Update Profile Photo",
    //Header
    aboutHeader: "About",
    homeHeader: "Home",
    forum: "Forum",
    edit_profile: "Edit Profile",
    sign_in: "Sign In",
    log_out: "Log Out",
    //Login Modal
    email_or_username: "Email",
    password: "Password",
    forgot: "Forgot?",
    wrong_username_or_password: "Wrong Username or Password",
    fields_missing: "YOU HAVE SOME FIELDS MISSING.",
    recover_password: "Recover Password",
    write_email_here: "Write Your Email Here",
    password_sent_label: "Password Recovery Link will Be Sent to your Email",
    next: "Next",
    reset_succesful: "Password Has Been Reset!",
    password_reset_error: "Invalid Token, Please Request New Reset Link",
    password_length_error: "Password must be at least 8 characters length",
    check_email_for_reset: "Сheck your email for reset link",
  },
  ka: {
    //Forum Page Langs
    announcement: "განცხადებები",
    pinned_posts: "აპინული პოსტები",
    post: "დაპოსტვა",
    add_photos: "სურათების დამატება",
    add_files: "ფაილების დამატება",
    selected: "არჩეული",
    write_post: "დაწერე პოსტი",
    search_forum: "ფორუმში ძებნა",
    all: "ყველა",
    new: "ახალი",
    top: "უმაღლესი",
    hot: "ტრენდული",
    like: "მოწონება",
    reply: "პასუხი",
    write_comment: "კომენტარის დაწერა",
    write_reply: "პასუხის დაწერა",
    comment_delete_question: "ადასტურებ, რომ გინდა კომენტარის წაშლა?",
    post_delete_question: "ადასტურებ, რომ გინდა პოსტის წაშლა?",
    yes: "კი",
    no: "არა",
    //GMGA Home Page Langs
    filter_by_category: "კატეგორიით ფილტრი:",
    search_by_name: "სახელის მიხედვით ძებნა:",
    no_guides_found: "მსგავსი გიდი ვერ მოიძებნა...",
    load_more_guides: "მეტი გიდის ნახვა",
    //Settings
    settings: "პარამეტრები",
    change_password: "პაროლის შეცვლა",
    current_password: "ახლანდელი პაროლი:",
    new_password: "ახალი პაროლი:",
    confirm_new_password: "დაადასტურე ახალი პაროლი:",
    please_fill_in_all_fields: "გთხოვთ შეავსოთ ყველა ველი",
    passwords_dont_match: "პაროლები ერთმანეთს არ ემთხვევა",
    incorrect_password: "არასწორი პაროლი",
    password_changed: "წარმატებული ცვლილება",
    //Profile
    activities: "აქტივობები",
    about: "შესახებ",
    posts: "პოსტები",
    //Edit Profile
    contact_info: "საკონტაქტო ინფორმაცია",
    languages: "ენები",
    about_you: "შენს შესახებ",
    save_changes: "დამახსოვრება",
    view_cover_photo: "ქოვერ სურათის ნახვა",
    update_cover_photo: "ქოვერ სურათის შეცვლა",
    view_profile_photo: "პროფილის სურათის ნახვა",
    update_profile_photo: "პროფილის სურათის შეცვლა",
    //Header
    aboutHeader: "ჩვენს შესახებ",
    homeHeader: "მთავარი გვერდი",
    forum: "ფორუმი",
    edit_profile: "პროფილის ცვლილება",
    sign_in: "ავტორიზება",
    log_out: "გამოსვლა",
    //Login Modal
    email_or_username: "ელ.ფოსტა",
    password: "პაროლი",
    forgot: "დაგავიწყდა?",
    wrong_username_or_password: "არასწორი მომხმარებლის სახელი ან პაროლი",
    fields_missing: "ყველა ველის შევსება სავალდებულოა",
    recover_password: "პაროლის აღდგენა",
    write_email_here: "დაწერეთ თქვენი ელ.ფოსტა",
    password_sent_label: "პაროლის აღდგენის ბმული მოგივათ ელ.ფოსტაზე",
    next: "შემდეგ",
    reset_succesful: "პაროლი აღდგენილია!",
    password_reset_error: "არასწორი ტოკენი, გთხოვთ სცადოთ ახალი ბმულით",
    password_length_error: "პაროლი უნდა შეიცავდეს 8 ან მეტ სიმბოლოს",
    check_email_for_reset: "შეამოწმეთ ელ.ფოსტა პაროლის აღსადგენად",
  },
});

const Language = () => {
  const { selectedLanguage } = useSelector((store) => store.user);
  useEffect(() => {
    lang.setLanguage(selectedLanguage);
  }, [selectedLanguage]);
  return <></>;
};

export default Language;
