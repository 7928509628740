//Libraries
import { useDispatch, useSelector } from "react-redux";
//Reducers
import { addPost, openAddPostModal } from "../features/newsFeedSlice";
import { lang } from "../utils/lang";

//Components
import MainButton from "./mainButton";
import CommentForm from "./commentForm";
//Assets
import cameraIcon from "../assets/images/cameraIcon.svg";
import attachIcon from "../assets/images/attachIcon.svg";

const AddPost = () => {
  const dispatch = useDispatch();

  const { userProfilePhoto } = useSelector((store) => store.singleProfile);

  const openModal = () => {
    dispatch(openAddPostModal());
  };

  return (
    <section className="addPostContainer">
      <div className="postInputContainer" onClick={openModal}>
        <CommentForm
          placeholder={lang.write_post}
          userImage={userProfilePhoto}
          depth={-1}
        />
      </div>

      <div className="addPostButtonsContainer">
        <span className="attachButtonsContainer">
          <label className="attachFileButton" onClick={openModal}>
            <img src={cameraIcon} alt="camera" />
            <span className="attachInformation">{lang.add_photos} </span>
          </label>

          <label className="attachFileButton" onClick={openModal}>
            <img src={attachIcon} alt="paperClip" />
            <span className="attachInformation">{lang.add_files} </span>
          </label>
        </span>
        <MainButton
          dark={true}
          text={lang.post}
          handleClick={openModal}
          className="addPostModalButton"
        />
      </div>
    </section>
  );
};
export default AddPost;
