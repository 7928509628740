/** @format */

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { customFetch } from "../utils/axios";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";

const initialState = {
  singleUser: null,
  posts: [],
  //As profile photo of logged is user should be retrieved from users profile this state/ function is needed
  userProfilePhoto: null,
  //Logged In User Profile Photo
  loggedInUserProfilePhoto: null,
  //States for uploading photos
  userIsLoading: false,
  coverPhotoIsUploading: false,
  uploadedCoverPhoto: [],
  profilePhotoIsUploading: false,
  uploadedProfilePhoto: [],
  uploadedGallery: [],
  addGalleryPhotosLoading: false,
  //States for deleting photos
  deletePhotoIsLoading: false,
  idOfPhotoToDelete: "",
};

//As profile photo of logged is user should be retrieved from users profile this state/ function is needed
export const getUserProfilePhoto = createAsyncThunk(
  "singleProfile/getUserProfilePhoto",
  async (_, thunkAPI) => {
    const { user } = thunkAPI.getState().user;
    let url = `profiles/view/${user?.user?.id}`;
    try {
      const resp = await customFetch.get(url, {});
      // console.log(resp.data);
      return resp.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const getSingleUser = createAsyncThunk(
  "singleProfile/getSingleUser",
  async (userId, thunkAPI) => {
    let url = `profiles/view/${userId}`;
    try {
      const resp = await customFetch.get(url, {});
      return resp.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const saveCoverPhoto = createAsyncThunk(
  "singleProfile/saveCoverPhoto",
  async (formData, thunkAPI) => {
    const { user } = thunkAPI.getState().user;
    let url = `user/update/${user?.user?.id}`;
    try {
      const resp = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${user.token}`,
        },
      });
      return resp.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

// export const saveProfilePhoto = createAsyncThunk(
//   "singleProfile/saveCoverPhoto",
//   async (formData, thunkAPI) => {
//     const { user } = thunkAPI.getState().user;
//     let url = `user/update/${user?.user?.id}`;
//     console.log(formData);
//     try {
//       const resp = await axios.post(url, formData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//           Authorization: `Bearer ${user.token}`,
//         },
//       });
//       console.log(resp.data);
//       return resp.data;
//     } catch (error) {
//       console.log(error);
//       return thunkAPI.rejectWithValue(error.response.data.msg);
//     }
//   }
// );

export const saveProfileInfo = createAsyncThunk(
  "singleProfile/saveUserInfo",
  async (dataInEditing, thunkAPI) => {
    const { user } = thunkAPI.getState().user;
    let url = `user/update/${user?.user?.id}`;
    try {
      const resp = await customFetch.post(url, dataInEditing);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

//-----------------------Thunks for Cover Photo Uploads-----------------------//

//------COVER PHOTO------//
export const addCoverPhoto = createAsyncThunk(
  "singleProfile/addCoverPhoto",
  async (formData, thunkAPI) => {
    const { user } = thunkAPI.getState().user;
    let url = "https://app.mountainguide.ge/api/v1/user/uploads/img";
    try {
      const resp = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${user.token}`,
        },
      });
      return resp.data;
    } catch (error) {
      console.log(error);
      toast.error("Image Size is Too Big", {
        position: "top-center",
        width: "fit-content",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const addCoverPhotoStringed = createAsyncThunk(
  "singleProfile/saveCoverPhotoStringed",
  async (dataInEditing, thunkAPI) => {
    const { user } = thunkAPI.getState().user;
    let url = `user/update/${user?.user?.id}`;
    try {
      const resp = await customFetch.post(url, dataInEditing);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

//------PROFILE PHOTO------//
export const addProfilePhoto = createAsyncThunk(
  "singleProfile/addProfilePhoto",
  async (formData, thunkAPI) => {
    const { user } = thunkAPI.getState().user;
    let url = "https://app.mountainguide.ge/api/v1/user/uploads/img";
    try {
      const resp = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${user.token}`,
        },
      });
      return resp.data;
    } catch (error) {
      console.log(error);
      toast.error("Image Size is Too Big", {
        position: "top-center",
        width: "fit-content",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const addProfilePhotoStringed = createAsyncThunk(
  "singleProfile/saveProfilePhotoStringed",
  async (dataInEditing, thunkAPI) => {
    const { user } = thunkAPI.getState().user;
    let url = `user/update/${user?.user?.id}`;
    try {
      const resp = await customFetch.post(url, dataInEditing);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

//------USER PHOTO GALLERY------//
export const addGalleryPhotos = createAsyncThunk(
  "singleProfile/addGallery",
  async (formData, thunkAPI) => {
    const { user } = thunkAPI.getState().user;
    let url = "https://app.mountainguide.ge/api/v1/user/uploads/img";
    try {
      const resp = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${user.token}`,
        },
      });
      return resp.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const addGalleryPhotosStringed = createAsyncThunk(
  "singleProfile/addGalleryStringed",
  async (galleryPhotosAr, thunkAPI) => {
    const { user } = thunkAPI.getState().user;
    let url = `user/update/${user?.user?.id}`;
    try {
      const resp = await customFetch.post(url, galleryPhotosAr);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

//Thunk to delete photo from Gallery
export const deletePhoto = createAsyncThunk(
  "newsFeed/deletePost",
  async (photoId, thunkAPI) => {
    // let url = `user/posts/destroy/${postId}`;
    let url = `user/destroyGallery/${photoId}`;
    try {
      const resp = await customFetch.post(url, {});
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);
const singleProfileSlice = createSlice({
  name: "singleProfile",
  initialState,
  reducers: {
    saveChangedUserInfo: (state, { payload }) => {
      return { ...state };
    },
    setIdOfPhotoToDelete: (state, { payload }) => {
      return { ...state, idOfPhotoToDelete: payload };
    },
  },
  extraReducers: {
    //Extra reducer for user's profile photo
    // [getUserProfilePhoto.pending]: (state) => {
    //   state.userIsLoading = true;
    // },
    [getUserProfilePhoto.fulfilled]: (state, { payload }) => {
      state.userProfilePhoto = payload.user.img;
    },
    // [getUserProfilePhoto.rejected]: (state, { payload }) => {
    //   state.userIsLoading = false;
    // },
    //
    [getSingleUser.pending]: (state) => {
      state.userIsLoading = true;
    },
    [getSingleUser.fulfilled]: (state, { payload }) => {
      state.singleUser = payload.user;
      state.userIsLoading = false;
    },
    [getSingleUser.rejected]: (state, { payload }) => {
      state.userIsLoading = false;
    },
    //Extra Reducers for Cover Photo
    [addCoverPhoto.pending]: (state) => {
      state.coverPhotoIsUploading = true;
    },
    [addCoverPhoto.fulfilled]: (state, { payload }) => {
      state.uploadedCoverPhoto = payload.img;
    },
    [addCoverPhoto.rejected]: (state, { payload }) => {
      state.coverPhotoIsUploading = false;
    },
    [addCoverPhotoStringed.pending]: (state) => {
      state.coverPhotoIsUploading = true;
    },
    [addCoverPhotoStringed.fulfilled]: (state, { payload }) => {
      state.coverPhotoIsUploading = false;
      state.singleUser = payload.user;
    },
    [addCoverPhotoStringed.rejected]: (state, { payload }) => {
      state.coverPhotoIsUploading = false;
    },
    //Extra Reducers for Profile Photo
    [addProfilePhoto.pending]: (state) => {
      state.profilePhotoIsUploading = true;
    },
    [addProfilePhoto.fulfilled]: (state, { payload }) => {
      state.uploadedProfilePhoto = payload.img;
    },
    [addProfilePhoto.rejected]: (state, { payload }) => {
      state.profilePhotoIsUploading = false;
    },
    [addProfilePhotoStringed.pending]: (state) => {
      state.profilePhotoIsUploading = true;
    },
    [addProfilePhotoStringed.fulfilled]: (state, { payload }) => {
      state.profilePhotoIsUploading = false;
      state.singleUser = payload.user;
    },
    [addProfilePhotoStringed.rejected]: (state, { payload }) => {
      state.profilePhotoIsUploading = false;
    },
    //Extra Reducers for Gallery
    [addGalleryPhotos.pending]: (state, { payload }) => {
      state.addGalleryPhotosLoading = true;
    },
    [addGalleryPhotos.fulfilled]: (state, { payload }) => {
      state.addGalleryPhotosLoading = false;
      state.uploadedGallery = state.uploadedGallery.concat(payload.img);
    },
    [addGalleryPhotos.false]: (state, { payload }) => {
      state.addGalleryPhotosLoading = false;
    },
    //Delete Photos Reducer
    [deletePhoto.pending]: (state, { payload }) => {
      state.deletePhotoIsLoading = true;
    },
    [deletePhoto.fulfilled]: (state, { payload }) => {
      state.idOfPhotoToDelete = "";
      state.deletePhotoIsLoading = false;
    },
    [deletePhoto.rejected]: (state, { payload }) => {
      state.idOfPhotoToDelete = "";
      state.deletePhotoIsLoading = false;
    },
  },
});
export const { saveChangedUserInfo, setIdOfPhotoToDelete } =
  singleProfileSlice.actions;
export default singleProfileSlice.reducer;
