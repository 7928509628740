import PostContent from "./postContent";
import { lang } from "../utils/lang";
import MainButton from "./mainButton";
import userProfileImage from "../assets/images/cardImg1.png";
import PostImageGallery from "./postImageGallery";

const ForumPinnedInfo = (props) => {
  const { type, posts } = props;
  switch (type) {
    case "announcements":
      return (
        <section className="forumPinnedInfoContainer">
          <h1 className="infoLabel">{lang.announcement}</h1>
          {posts.map((post, index) => {
            return (
              <div className="pinnedAnnouncement" key={index}>
                <PostContent pinned={true} post={post} />
              </div>
            );
          })}
        </section>
      );
    case "pinnedPosts":
      return (
        <section className="forumPinnedInfoContainer">
          <h1 className="infoLabel">{lang.pinned_posts}</h1>
          {posts.map((post, index) => {
            return (
              <div className="pinnedAnnouncement" key={index}>
                <PostContent pinned={true} post={post} />
              </div>
            );
          })}
        </section>
      );
    case "recentMedia":
      return (
        <section className="forumPinnedInfoContainer">
          <h1 className="infoLabel recentMediaInfoLabel">Recent Media</h1>
          <div className="recentMediaContainer">
            {/* <PostImageGallery images={content} /> */}
          </div>
        </section>
      );
    default:
      return <h1>ERROR</h1>;
  }
};

export default ForumPinnedInfo;
