/** @format */

//Libraries
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";
import { addDays, format } from "date-fns";
import { DateRange, DayPicker } from "react-day-picker";

//Router
import { setModalOpen } from "../features/imageGallerySlice";
import { getSingleUser } from "../features/singleProfileSlice";

//Components
import MainButton from "../components/mainButton";
import ImageGalleryModal from "../components/imageGalleryModal";
import ProfileCard from "../components/profileCard";
import Slider from "../components/profileSlider";

//Utils
import { imageLink } from "../utils/timeFormatter";
import { lang } from "../utils/lang";

//Assets
import InstagramIcon from "../assets/images/instagramIcon.svg";
import MailIcon from "../assets/images/mailIcon.svg";
import LanguagesIcon from "../assets/images/languageIcon.svg";
import PhoneIcon from "../assets/images/phoneIcon.svg";
import WhatsappIcon from "../assets/images/whatsappIcon.svg";

const pastMonth = new Date(2020, 10, 15);

const Profile = () => {
  // const scroll = useRef();

  const { galleryModalOpen } = useSelector((store) => store.imageGallery);
  const { singleUser } = useSelector((store) => store.singleProfile);
  const { selectedLanguage, user } = useSelector((store) => store.user);
  const dispatch = useDispatch();

  const [contactData, setContactData] = useState([]);

  const initialDays = [];
  const [days, setDays] = useState(initialDays);
  const defaultSelected = {
    from: pastMonth,
    to: addDays(pastMonth, 4),
  };
  const [range, setRange] = useState(defaultSelected);

  const [formData, setFormData] = useState({
    guides_id: singleUser?.id,
    name: "",
    email: "",
    phone: "",
    location: "",
    groupSize: "",
    date: "",
    date_start: "",
    date_end: "",
    message: "",
  });
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    if (singleUser) {
      setContactData([
        {
          item: singleUser.whatsapp && singleUser.whatsapp.slice(0, 20),
          itemType: "whatsapp",
          icon: WhatsappIcon,
        },
        {
          item: singleUser.instagram && singleUser.instagram,
          itemType: "instagram",
          icon: InstagramIcon,
        },
        {
          item: singleUser.email && singleUser.email.slice(0, 50),
          itemType: "contact",
          icon: MailIcon,
        },
        {
          item: singleUser.phone && singleUser.phone.slice(0, 20),
          itemType: "contact",
          icon: PhoneIcon,
        },
        {
          item: singleUser.language && singleUser.language.slice(0, 50),
          itemType: "language",
          icon: LanguagesIcon,
        },
      ]);
    }
  }, [singleUser]);

  const viewPhoto = (photo) => {
    const modalData = {
      scrollPosition: window.pageYOffset,
      galleryModalOpen: true,
      slideIndex: 1,
      images: [photo],
    };
    dispatch(setModalOpen(modalData));
  };

  // const scrollUp = () => {
  //   scroll.current.scrollIntoView({ behavior: "smooth", block: "start" });
  // };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //Function to fetch user data
  const { id } = useParams();
  useEffect(() => {
    dispatch(getSingleUser(id));
  }, []);

  const showAllCards = () => {
    // console.log("Show all cards");
  };

  const openPopup = () => {
    document.getElementById("popupContent").classList.toggle("d-flex");
  };

  var popupStepOne = document.getElementById("popupStepOne");
  var popupStepTwo = document.getElementById("popupStepTwo");

  const popupNextStep = (e) => {
    if (!validateForm(e)) {
      return;
    }
    popupStepOne.style.display = "none";
    popupStepTwo.style.display = "block";
  };

  const popupLastStep = () => {
    popupStepOne.style.display = "block";
    popupStepTwo.style.display = "none";
  };

  const performPayment = (e) => {
    e.preventDefault();
    console.log(singleUser);
    fetch("https://app.mountainguide.ge/api/v1/order/request", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...formData, guides_id: singleUser?.id }),
    })
      .then((response) => response.json())
      .then((data) => {
        // window.location.href = data?.redirect_url;
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("Something went wrong!");
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateForm = (e) => {
    e.preventDefault();
    const errors = {};
    let isValid = true;

    if (!formData.name) {
      errors.name = "Name is required";
      isValid = false;
    }

    if (!formData.email) {
      errors.email = "Email is required";
      isValid = false;
    }

    if (!formData.phone) {
      errors.phone = "Phone is required";
      isValid = false;
    }

    if (!formData.location) {
      errors.location = "Location is required";
      isValid = false;
    }

    if (!formData.groupSize) {
      errors.groupSize = "Group Size is required";
      isValid = false;
    }

    // if (!formData.date) {
    //   errors.date = "Date is required";
    //   isValid = false;
    // }

    if (!formData.date_start) {
      errors.date_start = "Date is required";
      isValid = false;
    }

    if (!formData.date_end) {
      errors.date_end = "Date is required";
      isValid = false;
    }

    if (!formData.message) {
      errors.message = "Message is required";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  if (galleryModalOpen) {
    return <ImageGalleryModal />;
    // } else if (userIsLoading) {
    //   return (
    //     <div className="loadingContainer">
    //       <Loading className={"loading"} />
    //     </div>
    //   );
  } else if (singleUser) {
    const { img, img_cover, trans, posts, tegs, nextPageUrl } = singleUser;
    const { description, name, surname } = trans;
    return (
      <section className="profilePage">
        <section className="profileBannersContainer">
          <div
            className="coverPhotoContainer"
            onClick={() => viewPhoto(img_cover)}
          >
            <img src={`${imageLink}${img_cover}`} alt="Profile Cover" />
          </div>
          <div className="profilePhotoAndNameContainer">
            <div
              className="profilePhotoContainer"
              onClick={() => viewPhoto(img)}
            >
              <span className="profileImage">
                <img src={`${imageLink}${img}`} alt="profileImg" />
              </span>
            </div>
            <div className="nameContainer" style={{ width: "100%" }}>
              <div className="pop-row">
                <h2 className="name">
                  {name} {surname}
                </h2>
                <MainButton
                  key={id}
                  className="headerMainButton"
                  dark={true}
                  text={"Book the Guide"}
                  handleClick={openPopup}
                />
              </div>
            </div>
          </div>
        </section>

        <section className="userInformationContainer">
          {tegs.length > 0 && (
            <>
              <h4 className="userSkillsLabel">{lang.activities}</h4>
              <div className="userSkillsContainer">
                {tegs.map((skill, index) => {
                  const { trans, id } = skill;
                  return (
                    <MainButton
                      key={id}
                      className="usersProfileSkills"
                      text={trans.title}
                    />
                  );
                })}
              </div>
            </>
          )}
          <div className="userProfileContactsContainer">
            {contactData.map((contact, index) => {
              const { item, itemType, icon } = contact;
              if (itemType === "whatsapp") {
                return (
                  <div className="userProfileContactItem" key={index}>
                    <div className="profileIconContainer">
                      <img src={icon} alt="contactImg" />
                    </div>
                    {item && <p className="profileContactsText">{item}</p>}
                  </div>
                );
              }
              if (itemType === "instagram") {
                return (
                  <div className="userProfileContactItem" key={index}>
                    <div className="profileIconContainer">
                      <img src={icon} alt="contactImg" />
                    </div>
                    <a href={item} className="profileContactsText">
                      Instagram
                    </a>
                  </div>
                );
              }
              return (
                <div className="userProfileContactItem" key={index}>
                  <div className="profileIconContainer">
                    <img src={icon} alt="contactImg" />
                  </div>
                  <p className="profileContactsText">{item}</p>
                </div>
              );
            })}
          </div>
          {description && (
            <div className="profileAboutContent">
              <h5 className="profileAboutLabel">
                {selectedLanguage === "en"
                  ? `${lang.about} ${name}`
                  : `${name}ს ${lang.about}`}
              </h5>
              {/* <p className="profileAboutText">{description}</p> */}
              <p
                className="profileAboutText"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </div>
          )}
          {singleUser && singleUser.gallery.length > 0 && (
            <div className="profileSliderContainer">
              <Slider
                gallery={singleUser.gallery}
                singleUser={singleUser}
                user={user}
              />
            </div>
          )}
        </section>
        {posts.length > 0 && (
          <section className="profileCardsContainer">
            <h2 className="profileCardsLabel">{lang.posts}</h2>
            <div className="postCardsContent">
              {posts.map((post, index) => {
                return (
                  <ProfileCard
                    key={index}
                    post={post}
                    name={name}
                    surname={surname}
                    img={img}
                    poster_id={id}
                  />
                );
              })}
            </div>
            {/* <div className="scrollerContent">
          <button className="btn scroller" onClick={scrollUp}>
            <img src={scroller} alt="scroller" />
          </button>
        </div> */}
            {nextPageUrl && (
              <div className="showMorePostsButtonContainer">
                <MainButton
                  handleClikc={showAllCards}
                  text={"SEE MORE POSTS"}
                  className="showMorePostsButton"
                />
              </div>
            )}
          </section>
        )}
        <section>
          <div className="popup-content" id="popupContent">
            <div className="popup-container">
              <button className="popup-colse-btn" onClick={openPopup}>
                <svg
                  width="14"
                  height="15"
                  viewBox="0 0 14 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.25977 0.0390625H3.37891L7.00195 5.45898L10.6445 0.0390625H12.7637L8.07617 7.04102L13.0762 14.6191H10.957L6.85547 8.41797L2.72461 14.6191H0.595703L5.80078 6.83594L1.25977 0.0390625Z"
                    fill="white"
                  />
                </svg>
              </button>
              <div className="scroll">
                <div id="popupStepOne">
                  <form onSubmit={validateForm}>
                    <input
                      type="text"
                      name="guides_id"
                      value={singleUser?.id}
                      hidden
                    />
                    <div className="mb-6">
                      <h2 className="popup-title">Booking</h2>
                      <div className="popup-row">
                        <div className="popup-col-4">
                          <label className="popup-label">Name</label>
                          <input
                            type="text"
                            placeholder="Name"
                            className="input"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                          />
                          {formErrors.name && (
                            <div className="error">{formErrors.name}</div>
                          )}
                        </div>
                        <div className="popup-col-4">
                          <label className="popup-label">Email</label>
                          <input
                            type="email"
                            placeholder="Email"
                            className="input"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                          />
                          {formErrors.email && (
                            <div className="error">{formErrors.email}</div>
                          )}
                        </div>
                        <div className="popup-col-4">
                          <label className="popup-label">Phone</label>
                          <input
                            type="text"
                            placeholder="Phone"
                            className="input"
                            name="phone"
                            value={formData.phone}
                            onChange={handleInputChange}
                            required
                          />
                          {formErrors.phone && (
                            <div className="error">{formErrors.phone}</div>
                          )}
                        </div>
                        <div className="popup-col-4">
                          <label className="popup-label">Location</label>
                          <input
                            type="text"
                            placeholder="Location"
                            className="input"
                            name="location"
                            value={formData.location}
                            onChange={handleInputChange}
                            required
                          />
                          {formErrors.location && (
                            <div className="error">{formErrors.location}</div>
                          )}
                        </div>
                        <div className="popup-col-4">
                          <label className="popup-label">Group Size</label>
                          <input
                            type="text"
                            placeholder="Group Size"
                            className="input"
                            name="groupSize"
                            value={formData.groupSize}
                            onChange={handleInputChange}
                            required
                          />
                          {formErrors.groupSize && (
                            <div className="error">{formErrors.groupSize}</div>
                          )}
                        </div>
                        <div className="popup-col-4">
                          <label className="popup-label">Date Start</label>
                          {/* <input
                            type="date"
                            placeholder="Date"
                            className="input"
                            name="date"
                            value={formData.date}
                            onChange={handleInputChange}
                            required
                          />
                          {formErrors.date && (
                            <div className="error">{formErrors.date}</div>
                          )} */}
                          <input
                            type="date"
                            placeholder="Date Start"
                            className="input"
                            name="date_start"
                            value={formData.date_start}
                            onChange={handleInputChange}
                            required
                          />
                          {formErrors.date && (
                            <div className="error">{formErrors.date_start}</div>
                          )}
                        </div>
                        <div className="popup-col-4">
                          <label className="popup-label">Date End</label>
                          <input
                            type="date"
                            placeholder="Date End"
                            className="input"
                            name="date_end"
                            value={formData.date_end}
                            onChange={handleInputChange}
                            required
                          />
                          {formErrors.date && (
                            <div className="error">{formErrors.date_end}</div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mb-6">
                      <h2 className="popup-title">You should know this</h2>
                      <div className="popup-row no-wrap">
                        <div className="popup-col-12">
                          <ul className="popup-list">
                            <li>
                              <p>
                                Please Read This information Before you complete
                                your Booking.
                              </p>
                            </li>
                            <li>
                              <p>
                                Your guide will be {name} {surname}
                              </p>
                            </li>
                            <li>
                              <p>
                                Please Make sure you list all the group members’
                                names in the box.
                              </p>
                            </li>
                            <li>
                              <p>
                                When making a reservation, an advance payment of
                                $50 is required. This amount will be deducted
                                from the total price of your tour at the end of
                                your booking. Please note that in the event of
                                cancellation, this amount will not be refunded.
                                Please be aware that, in accordance with
                                Georgian legislation, all transactions must be
                                conducted in Georgian Lari (GEL). Therefore, the
                                equivalent of $50 will be charged in GEL based
                                on the prevailing exchange rate on the date of
                                booking
                              </p>
                            </li>
                            <li>
                              <p>
                                To cancel the reservation, contact the guide via
                                email.
                              </p>
                            </li>
                          </ul>
                        </div>
                        <div className="popup-col-6">
                          <textarea
                            type="text"
                            placeholder="Message"
                            className="input textarea"
                            name="message"
                            value={formData.message}
                            onChange={handleInputChange}
                            required
                          ></textarea>
                          {formErrors.message && (
                            <div className="error">{formErrors.message}</div>
                          )}
                        </div>
                        <div className="popup-col-6">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <MainButton
                              className="popup-btn"
                              key={id}
                              dark={true}
                              text={"Next"}
                              handleClick={popupNextStep}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div id="popupStepTwo" style={{ display: "none" }}>
                  <h2 className="popup-title">Your Order</h2>
                  <div className="popup-table mb-3">
                    <h5 className="table-item-title">
                      Customer: {name} {surname}
                    </h5>
                    <div className="table-item">
                      <p className="left">Name:</p>
                      <p className="right">{formData.name}</p>
                    </div>
                    <div className="table-item">
                      <p className="left">Email</p>
                      <p className="right">{formData.email}</p>
                    </div>
                    <div className="table-item">
                      <p className="left">Phone</p>
                      <p className="right">{formData.phone}</p>
                    </div>
                    <div className="table-item">
                      <p className="left">Location</p>
                      <p className="right">{formData.location}</p>
                    </div>
                    <div className="table-item">
                      <p className="left">Group Size</p>
                      <p className="right">{formData.groupSize}</p>
                    </div>
                    <div className="table-item">
                      <p className="left">Date</p>
                      <p className="right">{formData.date}</p>
                    </div>
                    <div className="table-item">
                      <p className="left">Message</p>
                      <p className="right">{formData.message}</p>
                    </div>
                  </div>
                  <div className="popup-table mb-3">
                    <h5 className="table-item-title">
                      Selected guide: Giorgi Tsibakhashvili
                    </h5>
                    <div className="table-item">
                      <p className="left">Reserved seat:</p>
                      <p className="right">{formData.location}</p>
                    </div>
                    <div className="table-item">
                      <p className="left">Сhosen date:</p>
                      <p className="right">{formData.date}</p>
                    </div>
                    <div className="table-item last-table-item">
                      <p className="left">Subtotal</p>
                      <p className="right">$50</p>
                    </div>
                  </div>
                  <form onSubmit={performPayment}>
                    <div className="popup-terms mb-6">
                      <input type="checkbox" id="terms" required />
                      <label htmlFor="terms" style={{ marginLeft: 10 }}>
                        I have read and agree to the terms.
                      </label>
                    </div>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <div style={{ marginRight: 15 }}>
                        <MainButton
                          className="popup-btn"
                          key={id}
                          text={"Go back"}
                          handleClick={popupLastStep}
                        />
                      </div>
                      <MainButton
                        className="popup-btn"
                        key={id}
                        dark={true}
                        text={"Payment"}
                        // handleClick={performPayment}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="popup-overlay"></div>
          </div>
        </section>
      </section>
    );
  }
};
export default Profile;
