import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { customFetch } from "../utils/axios";


const initialState = {
  aboutIsLoading: false,
  aboutDescription: "",
  aboutCoverBanner: null,
  aboutText: "",
  missionText: "",
  aboutSliderData: [],
  missionSliderData: [],
};
export const getAboutInfo = createAsyncThunk(
  "about/getAboutInfo",
  async (_, thunkAPI) => {
    const { selectedLanguage } = thunkAPI.getState().user;
    // let url = `lang=${selectedLanguage}`;
    try {
      const resp = await customFetch.get(_, {});
      // console.log(resp.data);
      return resp.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

const aboutSlice = createSlice({
  name: "about",
  initialState,
  reducers: {},
  extraReducers: {
    [getAboutInfo.pending]: (state) => {
      state.aboutIsLoading = true;
    },
    [getAboutInfo.fulfilled]: (state, { payload }) => {
      state.aboutIsLoading = false;
      state.aboutDescription = payload.setting[0].trans.description;
      state.missionText = payload.setting[0].trans.description_mission;
      state.aboutSliderData = payload.setting[0].gallery_slider;
      state.missionSliderData = payload.setting[0].gallery_mission;
    },
    [getAboutInfo.rejected]: (state, { payload }) => {
      state.aboutIsLoading = false;
    },
  },
});

export const {} = aboutSlice.actions;
export default aboutSlice.reducer;
