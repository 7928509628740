import { NavLink } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
//Reducers
import { getUserProfilePhoto } from "../features/singleProfileSlice";
import { setWebsiteLanguage } from "../features/userSlice";
//Components
import MainButton from "../components/mainButton";
//Assets and Images
import headerLanguageEnglish from "../assets/images/headerLanguageEnglish.svg";
import headerLanguageGeorgian from "../assets/images/headerLanguageGeorgian.png";
import MobileBtn from "../assets/images/mobileBtn.png";
import sideBarOpen from "../assets/images/sidebarIcon.png";
import arrowIcon from "../assets/images/arrowIcon.svg";
import notificationIcon from "../assets/images/notificationIcon.svg";
import gmgaLogo from "../assets/images/gmgaLogo.png";
import gmgaSecondLogo from "../assets/images/gmgaSecondLogo.svg";
//Utils
import { removeUserFromLocalStorage } from "../utils/localstorage";
import { imageLink } from "../utils/timeFormatter";
import { lang } from "../utils/lang";
const Header = () => {
  const mobileNavBar = useRef();
  const headerDropdown = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //Data for header
  const { user, user_img, selectedLanguage } = useSelector(
    (store) => store.user
  );
  const { headerLogo } = useSelector((store) => store.navBar);
  const { userProfilePhoto } = useSelector((store) => store.singleProfile);

  //Dispatch to get logged in user's profile photo
  useEffect(() => {
    if (user) {
      dispatch(getUserProfilePhoto());
    }
  }, [user, dispatch]);

  //Navbar visibility states
  const [displayNavbar, setDisplayNavbar] = useState(false);
  const [settingsDropdownOpen, setSettingsDropdownOpen] = useState(false);

  //Function to handle language

  const handleLanguage = (language) => {
    dispatch(setWebsiteLanguage(language));
  };

  //Useffects for adding event listeners
  useEffect(() => {
    document.addEventListener("touchmove", function (e) {
      const body = document.querySelector("body");
      if (e.target.closest(".mobile-navbar") === mobileNavBar.current) {
        body.style.overflow = "hidden";
      } else {
        body.style.overflow = "visible";
      }
    });
    document.addEventListener("click", closeDropDown, true);
    document.addEventListener("click", closeSideBar, true);
    // if (window.width < 996) {
    //   document.addEventListener("click", closeSideBar, true);
    // }
  }, []);

  //Functions to handle SideBar
  const closeSideBar = (e) => {
    if (mobileNavBar.current && !mobileNavBar.current.contains(e.target)) {
      setDisplayNavbar(false);
    }
  };

  const closeDropDown = (e) => {
    if (headerDropdown.current && !headerDropdown.current.contains(e.target)) {
      setSettingsDropdownOpen(false);
    }
  };

  const handleHeaderDropdown = () => {
    setSettingsDropdownOpen(!settingsDropdownOpen);
  };

  //Functions to handle Mobile Navbar
  const closeMobileNavBar = () => {
    setDisplayNavbar(false);
  };

  const openMobileNavBar = () => {
    setDisplayNavbar(true);
  };

  //Functons to handle login
  const handleNavigateToLogin = () => {
    if (!user) {
      navigate("/login");
    }
  };

  const handleLogout = () => {
    navigate("/");
    window.location.reload();
    removeUserFromLocalStorage();
  };

  return (
    <header>
      <section className="headerContainer">
        <div className="headerLogoContainer">
          <NavLink to="/" className="headerLogo">
            <img src={gmgaLogo} alt="header-logo" />
          </NavLink>
          <NavLink to="/" className="headerLogo">
            <img src={gmgaSecondLogo} alt="header-logo" />
          </NavLink>
        </div>
        <ul className="headerMenu">
          <li className="headerOption">
            <NavLink to="/about">{lang.aboutHeader}</NavLink>
          </li>

          <li className="headerOption">
            <NavLink to="/">{lang.homeHeader}</NavLink>
          </li>

          <li className="headerOption">
            <button
              to="/language"
              className="headerLanguageContainer"
              onClick={
                selectedLanguage === "en"
                  ? () => handleLanguage("ka")
                  : () => handleLanguage("en")
              }
            >
              <img
                src={
                  selectedLanguage === "en"
                    ? headerLanguageGeorgian
                    : headerLanguageEnglish
                }
                alt="header-language"
              />
            </button>
          </li>
        </ul>
        <div className="userLoginHeaderContainer">
          {user ? (
            <>
              <NavLink to="/forum">
                <MainButton
                  text={lang.forum}
                  dark={true}
                  className="headerMainButton"
                />
              </NavLink>
              <NavLink to="/forum" className="notificationIconContainer">
                <img src={notificationIcon} alt="Notification" />
              </NavLink>
              <div className="loggedInUserImageContainer">
                <img
                  src={`${imageLink}${userProfilePhoto}`}
                  alt="you"
                  onClick={handleHeaderDropdown}
                />
              </div>
              <div
                className="additionalSettingsDropdownContainer"
                ref={headerDropdown}
              >
                <button
                  className={`additionalSettingsDropdownButton `}
                  onClick={handleHeaderDropdown}
                >
                  <img
                    src={arrowIcon}
                    alt="Arrow"
                    className={settingsDropdownOpen ? "arrowReversed" : ""}
                  />
                </button>
                {settingsDropdownOpen && (
                  <div className="additionalSettingsDropdown visible">
                    <NavLink
                      to="/editprofile"
                      className="additionalSettingsNavigation"
                      onClick={() => setSettingsDropdownOpen(false)}
                    >
                      {lang.edit_profile}
                    </NavLink>
                    <NavLink
                      to="/settings"
                      className="additionalSettingsNavigation"
                      onClick={() => setSettingsDropdownOpen(false)}
                    >
                      {lang.settings}
                    </NavLink>
                    <button
                      className="additionalSettingsNavigation"
                      onClick={() => handleLogout()}
                    >
                      {lang.log_out}
                    </button>
                  </div>
                )}
              </div>
            </>
          ) : (
            <MainButton
              text={lang.sign_in}
              dark={true}
              className="headerMainButton"
              handleClick={handleNavigateToLogin}
            />
          )}
        </div>
        <button className="sideBarBurger" onClick={openMobileNavBar}>
          <img className="sideBarOpen" src={sideBarOpen} alt="mobile-btn" />
        </button>
      </section>
      {/*<MobileHeader displayNavbar={displayNavbar} />*/}
      <section
        ref={mobileNavBar}
        className={`mobile-navbar mobile-navbar-container ${
          displayNavbar ? "display" : ""
        }`}
      >
        <div className="mobileCloseBtnContainer">
          <button onClick={closeMobileNavBar}>
            <img className="sideBarClose" src={MobileBtn} alt="mobileClose" />
          </button>
        </div>
        <div className="mobile-navbar-area">
          <ul className="mobile-area-menu">
            <li className="mobile-item-spacing">
              <NavLink to="/about">{lang.aboutHeader}</NavLink>
            </li>
            <li className="mobile-item-spacing">
              <NavLink to="/">{lang.homeHeader}</NavLink>
            </li>
            {user && (
              <>
                <li className="mobile-item-spacing">
                  <NavLink to="/forum">{lang.forum}</NavLink>
                </li>
                <li className="mobile-item-spacing">
                  <NavLink to="/editprofile">{lang.edit_profile}</NavLink>
                </li>
                <li className="mobile-item-spacing">
                  <NavLink to="/settings">{lang.settings}</NavLink>
                </li>
                <li>
                  <button
                    className="mobileLogOutButton"
                    onClick={() => handleLogout()}
                  >
                    {lang.log_out}
                  </button>
                </li>
              </>
            )}
            {/* <li> */}
            <button
              to="/language"
              className="headerLanguageContainer"
              onClick={
                selectedLanguage === "en"
                  ? () => handleLanguage("ka")
                  : () => handleLanguage("en")
              }
            >
              <img
                src={
                  selectedLanguage === "en"
                    ? headerLanguageGeorgian
                    : headerLanguageEnglish
                }
                alt="header-language"
              />
            </button>
            {/* </li> */}
            <div className="mobileUserLoginHeaderContainer">
              {!user && (
                <MainButton
                  text={lang.sign_in}
                  dark={true}
                  className="mobileHeaderMainButton"
                  handleClick={handleNavigateToLogin}
                />
              )}
            </div>
          </ul>
        </div>
      </section>
    </header>
  );
};

export default Header;
