//Library elements
import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Reducers
import {
  addPhoto,
  addPost,
  closeAddPostModal,
  getAllPosts,
} from "../features/newsFeedSlice";
import { lang } from "../utils/lang";
//Components
import MainButton from "./mainButton";
import Loading from "./loading";
import PostFormInput from "./postFormInput";
//Assets
import cameraIcon from "../assets/images/cameraIcon.svg";
import attachIcon from "../assets/images/attachIcon.svg";
import xMark from "../assets/images/xMark.svg";
//Utils
import { imageLink } from "../utils/timeFormatter";
const AddPostModal = () => {
  const dispatch = useDispatch();
  const { addPostIsLoading, addImagesArray, uploadsPhotosIsLoading } =
    useSelector((store) => store.newsFeed);
  const { user } = useSelector((store) => store.user);
  const { userProfilePhoto } = useSelector((store) => store.singleProfile);

  const [postData, setPostData] = useState({
    description: "",
    img: [],
    files: [],
    poster_id: user?.user?.id,
  });

  const [localImagesAr, setLocalImagesAr] = useState([]);

  //Click listener and Ref is added to close down modal if outside of modal is pressed
  const modalRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", closeModalOnBackground, true);
  }, []);

  useEffect(() => {
    // console.log(addImagesArray);
    setPostData({ ...postData, img: addImagesArray });
  }, [addImagesArray]);

  //FUNCTION TO SUBMIT NEW POST
  const handleAddNewPost = (e) => {
    e.preventDefault();
    dispatch(addPost(postData));
    dispatch(closeAddPostModal());
    //------------WHEN NEW API REQUEST MADE GET REQUEST IS ALSO DONE FOR INSTANT VISUALIZATION ----------//
    setTimeout(() => {
      dispatch(getAllPosts());
    }, 1000);
    //--------------------------------------------------------------------------------------------------//
  };

  //Functions to handle upload of data for posting
  const handlePhotoUpload = (event) => {
    setLocalImagesAr(event.target.files);
    for (let i = 0; i < event.target.files.length; i++) {
      const formData = new FormData();
      formData.append("img", event.target.files[i]);
      dispatch(addPhoto(formData));
    }
  };

  const handleFileUpload = (event) => {
    setPostData({ ...postData, files: event.target.files });
  };

  const handleText = (value) => {
    setPostData({ ...postData, description: value });
  };
  //--------------------------------------------//
  //Functions to close opened posting modal
  const closeModalOnBackground = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      dispatch(closeAddPostModal());
    }
  };

  const closeModal = () => {
    dispatch(closeAddPostModal());
  };

  return (
    <section className="addPostModalContainer">
      <div className="addPostModalBackground" />
      <form className="addPostModal" onSubmit={handleAddNewPost} ref={modalRef}>
        <ToastContainer />
        {addPostIsLoading || uploadsPhotosIsLoading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: `${modalRef.current.offsetWidth}px`,
              height: `${modalRef.current.offsetHeight}px`,
            }}
          >
            <Loading className={"loading loading-center"} />
          </div>
        ) : (
          <>
            <div className="addPostModalHeaderContainer">
              <button onClick={closeModal} className="closePostModalButton">
                <img src={xMark} alt="Close Modal" />
              </button>
            </div>
            <section className="addPostModalInputsContainer">
              <div className="postInputContainer">
                <PostFormInput
                  placeholder={lang.write_post}
                  userImage={`${imageLink}${userProfilePhoto}`}
                  handlePostText={handleText}
                  value={postData.description}
                />
              </div>

              <div className="addPostButtonsContainer">
                <span className="attachButtonsContainer">
                  <label className="attachFileButton">
                    <input
                      type="file"
                      multiple
                      onChange={handlePhotoUpload}
                      accept="image/jpeg, image/png"
                    />

                    <img src={cameraIcon} alt="camera" />
                    <span className="attachInformation">
                      {lang.add_photos}
                      {localImagesAr.length > 0 && (
                        <p className="photosSelected">
                          {postData.img.length} {lang.selected}{" "}
                        </p>
                      )}
                    </span>
                  </label>

                  <label className="attachFileButton">
                    <input
                      type="file"
                      multiple
                      onChange={handleFileUpload}
                      accept=".pdf, .doc, .docx"
                    />

                    <img src={attachIcon} alt="paperClip" />
                    <span className="attachInformation">
                      {lang.add_files}{" "}
                      {postData.files.length > 0 && (
                        <p className="photosSelected">
                          {postData.files.length} {lang.selected}{" "}
                        </p>
                      )}
                    </span>
                  </label>
                </span>
                <MainButton
                  dark={true}
                  text={lang.post}
                  handleClick={handleAddNewPost}
                  className="addPostModalButton"
                />
              </div>
            </section>
          </>
        )}
      </form>
    </section>
  );
};

export default AddPostModal;
