//Libraries
import { NavLink } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//Components
import SearchInput from "./searchInput";
import MainButton from "../components/mainButton";
//Assets and Data
import scroller from "../assets/images/scroller.svg";
import { lang } from "../utils/lang";
//Reducers
import {
  getGuidesInfo,
  incrementGuidesBatchNumber,
  getMoreGuides,
  filterUsersBySearch,
} from "../features/homeSlice";
import { filterUsersBySkill } from "../features/homeSlice";
//Utils
import { imageLink } from "../utils/timeFormatter";
import { Activities } from "../utils/data";

const HomeCards = () => {
  const dispatch = useDispatch();
  const {
    activities,
    guidesBatchNumber,
    userSearchValue,
    activityFilterId,
    guidesList,
    guidesListNextPageUrl,
  } = useSelector((store) => store.home);

  useEffect(() => {
    dispatch(getGuidesInfo());
  }, [activityFilterId, userSearchValue]);

  useEffect(() => {
    if (guidesBatchNumber > 1) {
      dispatch(getMoreGuides());
    }
  }, [guidesBatchNumber]);

  //Codes for scrolling up to the top of page
  const scroll = useRef();
  const scrollUp = () => {
    scroll.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  //Filter Users By skill
  const handleFilter = (acivity) => {
    dispatch(filterUsersBySkill(acivity));
  };

  //Filter Users By Search
  const handleSearch = (e) => {
    dispatch(filterUsersBySearch(e.target.value));
  };

  //Codes for fetching Guides and Pagination of Guides

  const loadMoreGuides = () => {
    dispatch(incrementGuidesBatchNumber());
  };

  return (
    <section ref={scroll} className="homeMainWrapper">
      <div className="homeMainInner">
        <div className="listOfGuidesLabelContainer">
          <h3 className="listOfGuidesLabel listOfGuidesLabelBlue">List Of</h3>
          <h3
            style={{ marginLeft: "0.3em" }}
            className="listOfGuidesLabel listOfGuidesLabelRed"
          >
            Guides
          </h3>
        </div>
        <div className="list-category-content">
          <div className="categoryButtons">
            <h6 className="list-category-title">{lang.filter_by_category}</h6>
            <div className="categoryFilterButtonsContainer">
              {activities &&
                activities.map((item, index) => {
                  const {
                    id,
                    trans: { title },
                  } = item;

                  return (
                    <button
                      key={index}
                      className={`skillsFilterButton standardButton ${
                        activityFilterId === id
                          ? " darkMainButton"
                          : "lightMainButton"
                      }`}
                      onClick={() => handleFilter(id)}
                    >
                      {title}
                    </button>
                  );
                })}
            </div>
          </div>
          <SearchInput
            label={lang.search_by_name}
            border={true}
            iconSize={"20px"}
            handleChange={handleSearch}
            className="guideSearchInput"
          />
        </div>
        <div className="homeCardsWrapper">
          {guidesList && guidesList.length > 0 ? (
            guidesList.map((card, index) => {
              const { trans, img, tegs, id } = card;
              const { name, surname, description } = trans;
              return (
                <div className="homeCard" key={index}>
                  <NavLink to={`profile/${id}`} className="homeCardImg">
                    <img src={`${imageLink}${img}`} alt="cardImg" />
                  </NavLink>
                  {/* <div className=""> */}
                  <h5 className="homeCardTitle">
                    {name} {surname}
                  </h5>
                  <div className="tegsAndTextContainer">
                    <h5
                      className="homeCardText"
                      dangerouslySetInnerHTML={{
                        __html: description
                          ? `${description.slice(0, 190)}...`
                          : "",
                      }}
                    ></h5>
                    <div className="skillsContainer">
                      {tegs.map((skill, index) => {
                        const {
                          trans: { title },
                        } = skill;
                        return (
                          <section className="singleSkillContainer" key={index}>
                            <MainButton text={title} className="skillButton" />
                          </section>
                        );
                      })}
                    </div>
                  </div>
                </div>
                // </div>
              );
            })
          ) : (
            <div className="noUsersFoundContainer">
              <h3 className="noEntriesFoundLabel">{lang.no_guides_found}.</h3>
            </div>
          )}
        </div>
        {guidesListNextPageUrl && (
          <MainButton
            text={lang.load_more_guides}
            className="loadMoreGuidesButton"
            dark={false}
            handleClick={() => loadMoreGuides()}
          />
        )}
      </div>
      <div className="scrollerContainer">
        <button className="btn scroller" onClick={scrollUp}>
          <img src={scroller} alt="scroller" />
        </button>
      </div>
    </section>
  );
};

export default HomeCards;
