/** @format */

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { customFetch } from "../utils/axios";
import { ToastContainer, toast } from "react-toastify";

const initialState = {
  //States for controlling modals
  addPostModalOpen: false,
  posts: [],
  pinnedPosts: [],
  announcements: [],
  addImagesArray: [],
  //States for loading of different data
  nextPageUrl: "",
  addPostIsLoading: false,
  newsFeedIsLoading: false,
  addCommentIsLoading: false,
  uploadsPhotosIsLoading: false,
  deleteCommentisLoading: false,
  deletePostisLoading: false,
  //States for filtering newsfeed
  postsBatchNumber: 1,
  filterType: "All",
  searchValue: "",
};

export const getAllPosts = createAsyncThunk(
  "newsFeed/getAllPosts",
  async (_, thunkAPI) => {
    const { filterType, postsBatchNumber, searchValue } =
      thunkAPI.getState().newsFeed;
    // let url = `user/posts?page=${postsBatchNumber}&take=${_?.take}&search=${searchValue}&condition=${filterType}`;
    let url = `user/posts?page=${postsBatchNumber}&search=${searchValue}&condition=${filterType}`;

    try {
      const resp = await customFetch.get(url, {});
      // console.log("standart", resp.data);
      return resp.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

// export const getAllPostsList = createAsyncThunk(
//   "newsFeed/getAllPostsList",
//   async (_, thunkAPI) => {
//     const { filterType, postsBatchNumber, searchValue } =
//       thunkAPI.getState().newsFeed;
//     let url = `user/posts/list?page=${postsBatchNumber}&search=${searchValue}&condition=${filterType}`;
//     // let url="user/posts/view/2"
//     try {
//       const resp = await customFetch.get(url, {});
//       console.log("list", resp.data);
//       return resp.data;
//     } catch (error) {
//       console.log(error);
//       return thunkAPI.rejectWithValue(error.response.data.msg);
//     }
//   }
// );

export const addPhoto = createAsyncThunk(
  "newsFeed/addPhoto",
  async (formData, thunkAPI) => {
    const { user } = thunkAPI.getState().user;
    let url = "https://app.mountainguide.ge/api/v1/user/uploads/img";
    try {
      const resp = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${user.token}`,
        },
      });
      return resp.data;
    } catch (error) {
      console.log(error);
      // alert("Image Size is Too Big");
      toast.error("One or more Images are too Big.", {
        position: "top-center",
        width: "fit-content",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const addPost = createAsyncThunk(
  "newsFeed/addPost",
  async (postData, thunkAPI) => {
    const { user } = thunkAPI.getState().user;
    let url = "user/posts/create";
    try {
      const resp = await customFetch.post(url, postData, {});
      return resp.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const likePost = createAsyncThunk(
  "newsFeed/likePost",
  async (postData, thunkAPI) => {
    let url = "user/post_likes/create";
    try {
      const resp = await customFetch.post(url, postData);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const unlikePost = createAsyncThunk(
  "newsFeed/unlikePost",
  async (postData, thunkAPI) => {
    let url = `user/post_likes/destroy/${postData}`;
    try {
      const resp = await customFetch.post(url, {});
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const addComment = createAsyncThunk(
  "newsFeed/addComment",
  async (commentData, thunkAPI) => {
    let user = "user/comments/create";
    try {
      const resp = await customFetch.post(user, commentData);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const deletePost = createAsyncThunk(
  "newsFeed/deletePost",
  async (postId, thunkAPI) => {
    let url = `user/posts/destroy/${postId}`;
    try {
      const resp = await customFetch.post(url, {});
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const deleteComment = createAsyncThunk(
  "newsFeed/deleteComment",
  async (commentId, thunkAPI) => {
    let url = `user/comments/destroy/${commentId}`;
    try {
      const resp = await customFetch.post(url, {});
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const likeComment = createAsyncThunk(
  "newsFeed/likeComment",
  async (commentData, thunkAPI) => {
    let url = "user/comment_likes/create";
    try {
      const resp = await customFetch.post(url, commentData);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const unlikeComment = createAsyncThunk(
  "newsFeed/unlikeComment",
  async (commentId, thunkAPI) => {
    let url = `user/comment_likes/destroy/${commentId}`;
    try {
      const resp = await customFetch.post(url, {});
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const sharePost = createAsyncThunk("/", async (postData, thunkAPI) => {
  // try {
  //   const resp = await customFetch.post("/", postData);
  //   return resp.data;
  // } catch (error) {
  //   return thunkAPI.rejectWithValue(error.response.data.msg);
  // }
});

const newsFeedSlice = createSlice({
  name: "newsFeed",
  initialState,
  reducers: {
    openAddPostModal: (state) => {
      document.body.style.overflow = "hidden";
      return { ...state, addPostModalOpen: true, addImagesArray: [] };
    },
    closeAddPostModal: (state) => {
      document.body.style.overflow = "auto";
      return { ...state, addPostModalOpen: false, addImagesArray: [] };
    },
    handleFilter: (state, { payload }) => {
      return { ...state, filterType: payload };
    },
    setSearchValue: (state, { payload }) => {
      return { ...state, searchValue: payload };
    },
    incrementPostsBatchNumber: (state, { payload }) => {
      return { ...state, postsBatchNumber: state.postsBatchNumber + 1 };
    },
    setPostsBatchNumber: (state, { payload }) => {
      return { ...state, postsBatchNumber: payload.page };
    },
    //Slice to like post locally so user does not have to wait for api
    handlePostLikeLocally: (state, { payload }) => {
      const { post_id, user_id } = payload;
      let updatedPosts = state.posts.map((post) => {
        const { like } = post;
        return post.id === post_id
          ? { ...post, like: like.concat({ id: user_id }) }
          : post;
      });

      return { ...state, posts: updatedPosts };
    },
    //Slice to unlike post locally so user does not have to wait for api
    handlePostUnlikeLocally: (state, { payload }) => {
      const { post_id, user_id } = payload;
      let updatedPosts = state.posts.map((post) => {
        const { like } = post;
        return post.id === post_id
          ? { ...post, like: like.filter((user) => user.id !== user_id) }
          : post;
      });
      return { ...state, posts: updatedPosts };
    },
  },
  extraReducers: {
    [getAllPosts.pending]: (state) => {},
    [getAllPosts.fulfilled]: (state, { payload }) => {
      // state.newsFeedIsLoading = false;
      // console.log(payload.posts.all.data);
      state.announcements = payload.posts.announcement;
      state.pinnedPosts = payload.posts.pinned;
      state.posts = payload.posts.all.data;
      state.nextPageUrl = payload.posts.all.next_page_url;
      //More to be added
    },
    [getAllPosts.rejected]: (state, { payload }) => {},
    // [getAllPostsList.pending]: (state) => {
    //   // state.newsFeedIsLoading = true;
    // },
    // [getAllPostsList.fulfilled]: (state, { payload }) => {
    //   // state.newsFeedIsLoading = false;
    //   let temp_posts = state.posts;
    //   state.posts = [...temp_posts, ...payload.posts.all.data];
    //   state.nextPageUrl = payload.posts.all.next_page_url;
    //   //More to be added
    // },
    // [getAllPostsList.rejected]: (state, { payload }) => {
    //   // state.newsFeedIsLoading = false;
    //   // state.posts = state.posts.concat(payload);
    //   //More to be added
    // },
    [addPhoto.pending]: (state) => {
      state.uploadsPhotosIsLoading = true;
    },
    [addPhoto.fulfilled]: (state, { payload }) => {
      state.uploadsPhotosIsLoading = false;
      state.addImagesArray = state.addImagesArray.concat(payload.img);
      // state.addPostModalOpen = false;
      //More to be added
    },
    [addPhoto.rejected]: (state, { payload }) => {
      state.uploadsPhotosIsLoading = false;
      // state.addPostModalOpen = false;
      //More to be added
    },
    [addComment.pending]: (state) => {
      state.addCommentIsLoading = true;
    },
    [addComment.fulfilled]: (state, { payload }) => {
      state.addCommentIsLoading = false;
      //More to be added
    },
    [addComment.rejected]: (state, { payload }) => {
      state.addCommentIsLoading = false;
      //More to be added
    },
    [deleteComment.pending]: (state) => {
      state.deleteCommentisLoading = true;
    },
    [deleteComment.fulfilled]: (state, { payload }) => {
      state.deleteCommentisLoading = false;
    },
    [deleteComment.rejected]: (state, { payload }) => {
      state.deleteCommentisLoading = false;
    },
    [deletePost.pending]: (state) => {
      state.deletePostisLoading = true;
    },
    [deletePost.fulfilled]: (state, { payload }) => {
      state.deletePostisLoading = false;
    },
    [deletePost.rejected]: (state, { payload }) => {
      state.deletePostisLoading = false;
    },
    [addPost.pending]: (state) => {
      state.addPostIsLoading = true;
    },
    [addPost.fulfilled]: (state, { payload }) => {
      state.addPostIsLoading = false;
    },
    [addPost.rejected]: (state, { payload }) => {
      state.addPostIsLoading = false;
    },
  },
});

export const {
  openAddPostModal,
  closeAddPostModal,
  handleFilter,
  handlePostLikeLocally,
  handlePostUnlikeLocally,
  addPostLocally,
  deletePostLocally,
  incrementPostsBatchNumber,
  setPostsBatchNumber,
  setSearchValue,
} = newsFeedSlice.actions;
export default newsFeedSlice.reducer;
