//Libraries
import { useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
//Components
import Icon from "./postStatisticsIcon";
import ConfirmationModal from "./confirmationModal";
import PostImageGallery from "./postImageGallery";
//Reducers
import { deletePost } from "../features/newsFeedSlice";
import { getSingleUser } from "../features/singleProfileSlice";
//Assets
import downloadIcon from "../assets/images/download.svg";
import likeIcon from "../assets/images/like.svg";
import commentsIcon from "../assets/images/message.svg";
import trashbinIcon from "../assets/images/trashbinIcon.svg";
//Utils
import {
  dateToTimestamp,
  timeFormatter,
  imageLink,
} from "../utils/timeFormatter";
const ProfileCard = (props) => {
  const { name, surname, post, img, poster_id } = props;
  const {
    created_at,
    profile,
    description,
    usersView,
    usersHandle,
    like,
    comments_count,
    gallery,
    user_id,
    id,
  } = post;

  const dispatch = useDispatch();

  const { user } = useSelector((store) => store.user);
  //Functions to handle Delete Post Modal
  const [deletePostModal, setDeletePostModal] = useState(false);

  const toggleDeletePostModal = () => {
    setDeletePostModal(!deletePostModal);
  };

  const closeDeletePostModal = () => {
    setDeletePostModal(false);
  };

  const handleDeletePost = () => {
    // dispatch(deletePost({ user_id: user_id, post_id: "7-11-DELETEID" }));
    dispatch(deletePost(id));
    setDeletePostModal(false);
    //------------WHEN NEW API REQUEST MADE GET REQUEST IS ALSO DONE FOR INSTANT VISUALIZATION ----------//
    setTimeout(() => {
      dispatch(getSingleUser(poster_id));
    }, 1000);
    //--------------------------------------------------------------------------------------------------//
  };

  //Useeffect to show and hide overflow bar
  useEffect(() => {
    if (deletePostModal) {
      document.body.style.overflow = "hidden";
    } else if (!deletePostModal) {
      document.body.style.overflow = "auto";
    }
  }, [deletePostModal]);

  return (
    <section className="singleUserPostContainer">
      {deletePostModal && (
        <ConfirmationModal
          handleYes={handleDeletePost}
          handleNo={toggleDeletePostModal}
          handleRefClick={closeDeletePostModal}
          question="Are you sure you want to delete this post?"
        />
      )}
      <div className="singleUserPostHeader">
        <span className="posterProfileImageContainer">
          <img src={`${imageLink}${img}`} alt="User" />
        </span>
        <span className="otherPostInformationContainer">
          <p className="posterName">
            {name} {surname}
          </p>
          <p className="postDate">
            {timeFormatter(dateToTimestamp(created_at))}
          </p>
        </span>
      </div>
      {description && <p className="profileCardMainText">{description}</p>}
      {/* <NavLink to="*" className="profileCardImageContainer">
        {post.img ? <img src={img} alt="profileCardImg" /> : ""}
      </NavLink> */}
      {gallery && gallery.length > 0 && (
        <PostImageGallery images={gallery} pinned={true} />
      )}
      <section className="profileCardReactions">
        <div className="handleResults">
          <Icon
            icon={likeIcon}
            count={like.length}
            blue={false}
            pinned={false}
            // handleClick={() => handleReactButton("like")}
          />
          {/* <Icon
            icon={commentsIcon}
            count={comments_count}
            blue={false}
            pinned={false}
            // handleClick={() => handleReactButton("like")}
          /> */}
          {user && user_id === user?.user?.id && (
            <Icon
              icon={trashbinIcon}
              blue={false}
              pinned={false}
              handleClick={toggleDeletePostModal}
            />
          )}
        </div>
      </section>
    </section>
  );
};

export default ProfileCard;
