import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { customFetch } from "../utils/axios";
import {
  getUserFromLocalStorage,
  addUserToLocalStorage,
  getLanguageFromLocalStorage,
  addLanguageToLocalStorage,
} from "../utils/localstorage";
import { lang } from "../utils/lang";
const initialState = {
  isLoading: false,
  user: getUserFromLocalStorage() || null,
  user_img: "",
  passwordChangeNotification: { success: false, text: "" },
  accountDeactivationNotification: { success: false, text: "" },
  errorCode: null,
  passwordResetErrorMessage: "",
  //Website Language state
  selectedLanguage: getLanguageFromLocalStorage() || "en",
  //Loading States
  passwordResetIsLoading: false,
  loginLoading: false,
};

export const loginUser = createAsyncThunk(
  "user/loginUser",
  async (loginData, thunkAPI) => {
    let url = "auth/signin";
    try {
      const resp = await customFetch.post(url, loginData);
      // console.log(resp.data);
      return resp.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue(error.response.status);
    }
  }
);

//Thunk to reset password
export const resetPassword = createAsyncThunk(
  "user/resetPassword",
  async (passwordData, thunkAPI) => {
    let url = "auth/password/reset/update";
    // console.log(passwordData);
    try {
      const resp = await customFetch.post(url, passwordData);
      // console.log(resp.data);
      return resp.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue(error.response.status);
    }
  }
);

//Thunk to send password reset link to email
export const recoverPassword = createAsyncThunk(
  "user/recoverPassword",
  async (recoveryEmail, thunkAPI) => {
    // console.log(recoveryEmail);
    let url = "auth/password/reset/send";
    try {
      const resp = await customFetch.post(url, recoveryEmail);
      // console.log(resp.data);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const changePassword = createAsyncThunk(
  "user/changePassword",
  async (passwordData, thunkAPI) => {
    // console.log(passwordData);
    let url = "user/auth/change-password";
    try {
      const resp = await customFetch.post(url, passwordData);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const handleUserPostVisibility = createAsyncThunk(
  "/",
  async (visibilityType, thunkAPI) => {
    // console.log(visibilityType);
    // try {
    //   const resp = await customFetch.post("/", passwordData);
    //   return resp.data;
    // } catch (error) {
    //   return thunkAPI.rejectWithValue(error.response.data.msg);
    // }
  }
);

export const deactivateAccount = createAsyncThunk(
  "/",
  async (userData, thunkAPI) => {
    // console.log(userData);
    return false;
    // try {
    //   const resp = await customFetch.post("/", passwordData);
    //   return resp.data;
    // } catch (error) {
    //   return thunkAPI.rejectWithValue(error.response.data.msg);
    // }
  }
);

export const saveCalendar = createAsyncThunk(
  "user/calendars/create",
  async (data, thunkAPI) => {
    let url = "user/calendars/create";
    try {
      const res = await customFetch.post(url, data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setNewPasswordsMathingError: (state, { payload }) => {
      return {
        ...state,
        passwordChangeNotification: { success: false, text: payload },
      };
    },
    setAccountDeactivationError: (state, { payload }) => {
      return {
        ...state,
        accountDeactivationNotification: { success: false, text: payload },
      };
    },
    setWebsiteLanguage: (state, { payload }) => {
      addLanguageToLocalStorage(payload);
      console.log(payload);
      window.location.reload();
      return { ...state, selectedLanguage: payload };
    },
  },
  extraReducers: {
    [loginUser.pending]: (state) => {
      state.loginLoading = true;
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      state.loginLoading = false;
      addUserToLocalStorage(payload);
      state.user = payload;
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.errorCode = payload;
      state.loginLoading = false;
    },
    [resetPassword.pending]: (state) => {
      state.passwordResetIsLoading = true;
      state.passwordResetErrorMessage = "";
    },
    [resetPassword.fulfilled]: (state) => {
      state.passwordResetIsLoading = false;
      state.passwordResetErrorMessage = lang.reset_succesful;
    },
    [resetPassword.rejected]: (state) => {
      state.passwordResetErrorMessage = lang.password_reset_error;
      state.passwordResetIsLoading = false;
      //More to be added
    },
    [recoverPassword.pending]: (state) => {
      state.isLoading = true;
    },
    [recoverPassword.fulfilled]: (state) => {
      state.isLoading = false;
      //More to be added
    },
    [recoverPassword.rejected]: (state) => {
      state.isLoading = false;
      //More to be added
    },
    [changePassword.pending]: (state) => {},
    [changePassword.fulfilled]: (state) => {
      state.passwordChangeNotification = {
        success: true,
        text: lang.password_changed,
      };
      //More to be added
    },
    [changePassword.rejected]: (state) => {
      state.passwordChangeNotification = {
        success: false,
        text: lang.incorrect_password,
      };
      //More to be added
    },
    [deactivateAccount.pending]: (state) => {
      state.isLoading = true;
    },
    [deactivateAccount.fulfilled]: (state) => {
      state.isLoading = false;
      state.accountDeactivationNotification = {
        success: false,
        text: "",
      };
      //More to be added
    },
    [deactivateAccount.rejected]: (state) => {
      state.isLoading = false;
      state.accountDeactivationNotification = {
        success: false,
        text: lang.incorrect_password,
      };
      //More to be added
    },
  },
});

export const {
  setNewPasswordsMathingError,
  setAccountDeactivationError,
  setWebsiteLanguage,
} = userSlice.actions;
export default userSlice.reducer;
