/** @format */

//Libraries
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { toggleModal } from "../features/imageGallerySlice";
//Reducers
import {
  getAllPosts,
  // getAllPostsList,
  handleFilter,
  setSearchValue,
} from "../features/newsFeedSlice";
import { lang } from "../utils/lang";

//Components
import ForumSidebar from "../components/forumSidebar";
import AddPost from "../components/addPost";
import SearchInput from "../components/searchInput";
import SinglePost from "../components/singlePost";
import ForumPinnedInfo from "../components/forumPinnedInfo";
import ImageGalleryModal from "../components/imageGalleryModal";
import AddPostModal from "../components/addPostModal";
import Loading from "../components/loading";
import NewsfeedFilterButton from "../components/newsfeedFilterButton";
import MainButton from "../components/mainButton";
//Assets
import ForumBanner from "../assets/images/forumBanner.svg";
import clockIcon from "../assets/images/clockIcon.svg";
import arrowtopIcon from "../assets/images/arrowtopIcon.svg";
import fireIcon from "../assets/images/fireIcon.svg";
const Forum = () => {
  const dispatch = useDispatch();
  const { scrollPosition, galleryModalOpen } = useSelector(
    (store) => store.imageGallery
  );
  const { user } = useSelector((store) => store.user);
  const {
    addPostModalOpen,
    posts,
    newsFeedIsLoading,
    // postsBatchNumber,
    searchValue,
    pinnedPosts,
    announcements,
    // recentMedia,
    // nextPageUrl,
  } = useSelector((store) => store.newsFeed);
  // const [randomNumber, setRandomNumber] = useState(2);
  //Return to old position when modal closes
  useEffect(() => {
    if (galleryModalOpen === false) {
      window.scrollTo(0, scrollPosition);
    }
  }, [galleryModalOpen]);

  //Functions to load more posts on press of button or every 5 Seonds (When random number is useffect above changes)
  useEffect(() => {
    dispatch(getAllPosts());
  }, []);

  // const loadMorePosts = () => {
  //   dispatch(incrementPostsBatchNumber());
  //   dispatch(getAllPostsList());
  // };

  useEffect(() => {
    setInterval(() => {
      // setRandomNumber(Math.floor(Math.random() * 10000));
      // let temp_page = postsBatchNumber;
      // dispatch(setPostsBatchNumber({ page: 1 }));
      // console.log(temp_page);
      // dispatch(getAllPosts({ take: temp_page * 2, page: postsBatchNumber }));
      dispatch(getAllPosts());
    }, 8000);
  }, []);

  //Function to dipatch newsfeed filtering by category
  const setFilter = (type) => {
    dispatch(handleFilter(type));
    //------------WHEN NEW API REQUEST MADE GET REQUEST IS ALSO DONE FOR INSTANT VISUALIZATION ----------//
    setTimeout(() => {
      dispatch(getAllPosts());
    }, 400);
    //--------------------------------------------------------------------------------------------------//
  };

  //Function to dispatch newsfeed filtering by search value
  const setSearch = (e) => {
    dispatch(setSearchValue(e.target.value));
    //------------WHEN NEW API REQUEST MADE GET REQUEST IS ALSO DONE FOR INSTANT VISUALIZATION ----------//
    setTimeout(() => {
      dispatch(getAllPosts());
    }, 400);
    //--------------------------------------------------------------------------------------------------//
  };

  return (
    <>
      {galleryModalOpen ? (
        <ImageGalleryModal />
      ) : (
        <section className="forumPage">
          {addPostModalOpen && <AddPostModal />}
          {!newsFeedIsLoading ? (
            <>
              {/* <button onClick={() => toast("Wow so easy!")}>BUTTON</button> */}
              <div className="forumBannerContainer">
                <img src={ForumBanner} alt="Mountains and Sun Vector" />
              </div>
              <main className="forumMain">
                <div className="forumSideBarCcontainer">
                  {announcements && (
                    <ForumPinnedInfo
                      type="announcements"
                      posts={announcements}
                    />
                  )}
                </div>
                <section className="newsfeedContainer">
                  {user && <AddPost />}
                  <div className="newsfeedFiltersContainer">
                    <SearchInput
                      iconSize={"15px"}
                      fontSize={"14px"}
                      fontWeight={"500"}
                      placeholder={lang.search_forum}
                      height={"45px"}
                      label={true}
                      handleChange={setSearch}
                      value={searchValue}
                      containerClassName="forumSearchContainer"
                    />
                    <div className="newsfeedFilterButtonsContainer">
                      <NewsfeedFilterButton
                        icon={clockIcon}
                        text={lang.all}
                        handleClick={() => setFilter("All")}
                      />
                      {/* <NewsfeedFilterButton
                        icon={clockIcon}
                        text={lang.new}
                        handleClick={() => setFilter("New")}
                      /> */}
                      <NewsfeedFilterButton
                        icon={arrowtopIcon}
                        text={lang.top}
                        handleClick={() => setFilter("Top")}
                      />
                      <NewsfeedFilterButton
                        icon={fireIcon}
                        text={lang.hot}
                        handleClick={() => setFilter("Hot")}
                      />
                    </div>
                  </div>

                  {posts.length > 0 ? (
                    posts.map((post, index) => {
                      return <SinglePost key={index} post={post} />;
                    })
                  ) : (
                    <div className="noPostsFoundContainer">
                      <h3 className="noPostsFoundLabel">No Posts Found...</h3>
                    </div>
                  )}

                  {/* {nextPageUrl && (
                    <MainButton
                      text={"Load More Posts"}
                      className="loadMorePostsButton"
                      handleClick={loadMorePosts}
                    />
                  )} */}
                </section>
                <div className="forumAdditionalInfoContainer">
                  {pinnedPosts && (
                    <ForumPinnedInfo type="pinnedPosts" posts={pinnedPosts} />
                  )}
                  {/* {recentMedia && (
                    <ForumPinnedInfo
                      type="recentMedia"
                      content={imageContent.slice(0, 0)}
                    />
                  )} */}
                </div>
              </main>
            </>
          ) : (
            <div className="loadingContainer">
              <Loading className={"loading"} />
            </div>
          )}
        </section>
      )}
    </>
  );
};

export default Forum;
