import axios from "axios";
import {
  getUserFromLocalStorage,
  getLanguageFromLocalStorage,
} from "./localstorage";

export const customFetch = axios.create({
  baseURL: "https://app.mountainguide.ge/api/v1",
  params: {
    lang: getLanguageFromLocalStorage(),
  },
});

customFetch.interceptors.request.use((config) => {
  const user = getUserFromLocalStorage();
  if (user) {
    // config.headers.common["Authorization"] = `Bearer ${user.token}`;
    config.headers["Authorization"] = `Bearer ${user.token}`;
  }
  return config;
});

// export const customPhotoUpload = axios.create({
//   baseURL: "https://app.mountainguide.ge/api/v1/user/uploads/img",
// });

// customPhotoUpload.interceptors.request.use((config) => {
//   const user = getUserFromLocalStorage();
//   if (user) {
//     // config.headers.common["Authorization"] = `Bearer ${user.token}`;
//     config.headers["Authorization"] = `Bearer ${user.token}`;
//     config.headers["Content-Type"] = "multipart/form-data";
//   }
//   return config;
// });

// export default customFetch;
