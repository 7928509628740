export const addUserToLocalStorage = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};

export const removeUserFromLocalStorage = () => {
  localStorage.removeItem("user");
};

export const getUserFromLocalStorage = () => {
  const result = localStorage.getItem("user");
  const user = result ? JSON.parse(result) : null;
  return user;
};

export const addLanguageToLocalStorage = (language) => {
  localStorage.setItem("lang", JSON.stringify(language));
};

export const getLanguageFromLocalStorage = () => {
  const result = localStorage.getItem("lang");
  const language = result ? JSON.parse(result) : null;
  return language;
};
