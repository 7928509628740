//Libraries
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
//Reducers
import { resetPassword } from "../features/userSlice";
//Utils
import { lang } from "../utils/lang";
//Components
import Loading from "../components/loading";
const PasswordReset = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, passwordResetErrorMessage, passwordResetIsLoading } =
    useSelector((store) => store.user);
  const { token } = useParams();

  const [newPasswordData, setNewPasswordData] = useState({
    newPassword: "",
    password_confirm: "",
  });

  const [verificationError, setVerificationError] = useState("");

  //Ref is added to control width and height of modal on loading
  const modalRef = useRef(null);

  const handleReset = (e) => {
    e.preventDefault();
    if (newPasswordData.newPassword.length < 8) {
      setVerificationError(lang.password_length_error);
      return;
    }
    if (!newPasswordData.newPassword || !newPasswordData.password_confirm) {
      setVerificationError(lang.fields_missing);
      return;
    }
    if (newPasswordData.newPassword !== newPasswordData.password_confirm) {
      setVerificationError(lang.passwords_dont_match);
      return;
    }
    dispatch(
      resetPassword({
        password: newPasswordData.newPassword,
        password_confirm: newPasswordData.password_confirm,
        token: token,
      })
    );
    setNewPasswordData({
      newPassword: "",
      password_confirm: "",
    });
    setVerificationError("");
  };

  //Use effect to navigate on login page if reset is successful
  useEffect(() => {
    console.log(passwordResetErrorMessage);
    console.log(lang.password_changed);
    if (passwordResetErrorMessage === lang.password_changed) {
      navigate("/login");
    }
  }, [passwordResetErrorMessage]);

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setNewPasswordData({ ...newPasswordData, [name]: value });
  };

  return (
    <span className="passwordResetPage">
      <section className="passwordResetModal">
        <form
          className="passwordResetForm"
          onSubmit={handleReset}
          ref={modalRef}
        >
          {passwordResetIsLoading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: `${modalRef.current.offsetWidth}px`,
                height: `${modalRef.current.offsetHeight}px`,
              }}
            >
              <Loading className={"loading loading-center"} />
            </div>
          ) : (
            <>
              <h3 className="modalLabel">{lang.recover_password}</h3>
              <div className="passwordResetInputsContainer">
                <span className="singleInputContainer">
                  <input
                    className="passwordResetInput"
                    type="password"
                    name="newPassword"
                    onChange={handleChange}
                    placeholder={lang.new_password}
                  />
                </span>
                <span className="singleInputContainer">
                  <input
                    className="passwordResetInput"
                    type="password"
                    name="password_confirm"
                    onChange={handleChange}
                    placeholder={lang.confirm_new_password}
                  />
                </span>
                {(passwordResetErrorMessage || verificationError) && (
                  <p className="errorMessageLabel">
                    {verificationError || passwordResetErrorMessage}
                  </p>
                )}
              </div>
              <button type="submit" className="resetPasswordSubmitButton">
                {lang.recover_password}
              </button>
            </>
          )}
        </form>
      </section>
    </span>
  );
};

export default PasswordReset;
