import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { customFetch } from "../utils/axios";
const initialState = {
  guidesIsLoading: false,
  activities: [],
  homeMainDescription: "",
  guidesList: [],
  //Guides filtering states
  activityFilterId: 0,
  guidesBatchNumber: 1,
  userSearchValue: "",
  guidesListNextPageUrl: "",
};

export const getGuidesInfo = createAsyncThunk(
  "home/getGuidesInfo",
  async (_, thunkAPI) => {
    const { guidesBatchNumber, activityFilterId, userSearchValue } =
      thunkAPI.getState().home;
    // let url = `main?page=${guidesBadgeNumber}?id=${activityFilterId}?search=${userSearchValue}`;
    let url = `main?teg_id=${activityFilterId}&search=${userSearchValue}`;
    // let url = "main";
    // let filterData = { teg_id: activityFilterId, search: userSearchValue };
    try {
      const resp = await customFetch.get(url, {});
      return resp.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const getMoreGuides = createAsyncThunk(
  "home/getMoreGuides",
  async (_, thunkAPI) => {
    const { guidesBatchNumber, activityFilterId, userSearchValue } =
      thunkAPI.getState().home;
    // let url = `main?page=${guidesBadgeNumber}?id=${activityFilterId}?search=${userSearchValue}`;
    let url = `main?page=${guidesBatchNumber}&teg_id=${activityFilterId}&search=${userSearchValue}`;
    // let url = "main";
    // let filterData = { teg_id: activityFilterId, search: userSearchValue };
    try {
      const resp = await customFetch.get(url, {});
      return resp.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    filterUsersBySkill: (state, { payload }) => {
      if (state.activityFilterId === payload) {
        return { ...state, activityFilterId: 0, guidesBatchNumber: 1 };
      } else {
        return { ...state, activityFilterId: payload, guidesBatchNumber: 1 };
      }
    },

    filterUsersBySearch: (state, { payload }) => {
      return { ...state, userSearchValue: payload, guidesBatchNumber: 1 };
    },
    incrementGuidesBatchNumber: (state) => {
      return { ...state, guidesBatchNumber: state.guidesBatchNumber + 1 };
    },
  },
  extraReducers: {
    [getGuidesInfo.pending]: (state) => {
      state.guidesIsLoading = true;
    },
    [getGuidesInfo.fulfilled]: (state, { payload }) => {
      state.guidesList = payload.user.data;
      state.guidesListNextPageUrl = payload.user.next_page_url;
      state.guidesIsLoading = false;
      state.activities = payload.tegs;
      state.homeMainDescription = payload.setting[0].trans.description_min;
    },
    [getGuidesInfo.rejected]: (state, { payload }) => {
      state.guidesIsLoading = false;
    },
    [getMoreGuides.fulfilled]: (state, { payload }) => {
      state.guidesListNextPageUrl = payload.user.next_page_url;
      state.guidesList = state.guidesList.concat(payload.user.data);
    },
  },
});

export const {
  filterUsersBySkill,
  filterUsersBySearch,
  setGuideBadgeNumber,
  incrementGuidesBatchNumber,
} = homeSlice.actions;
export default homeSlice.reducer;

// let url = `main?ids=${17}`;
