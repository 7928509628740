import xMark from "../assets/images/xMarkBold.svg";
import MainButton from "./mainButton";
import Loading from "./loading";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router";
import { loginUser, recoverPassword } from "../features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { lang } from "../utils/lang";
const initialState = {
  email: "",
  recoverEmail: "",
  password: "",
};

const LoginModal = ({ closeLoginForm }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { errorCode, user, user_token, loginLoading } = useSelector(
    (store) => store.user
  );

  const [recoverEmail, setRecoverEmail] = useState("");

  //Ref is added to control width and height of modal on loading
  const modalRef = useRef(null);

  //States of user information
  const [values, setValues] = useState(initialState);

  //States of modal stages, defines in which stage user is
  const [passwordForgot, setPasswordForgot] = useState(false);
  const [passwordRecovered, setPasswordRecovered] = useState(false);
  const [verificationError, setVerificationError] = useState("");

  //Navigate back to previous page on modal close
  const closeLoginModal = () => {
    navigate(-1);
  };

  //Naviatge user to password recovery via email form
  const initiatePasswordForgot = () => {
    setVerificationError("");
    setPasswordForgot(true);
  };

  //Login user
  const handleLogin = (e) => {
    e.preventDefault();
    //Verification that all fields are filled in
    if (values.email === "" || values.password === "") {
      setVerificationError(lang.fields_missing);
      return;
    }
    dispatch(loginUser({ email: values.email, password: values.password }));

    //If login succesful user redirected to previous page, if not error message is set and deisplayed

    // if (user) {
    //   closeLoginForm();
    // } else {
    //   setVerificationError("Wrong username or password.");
    //   return;
    // }
  };

  useEffect(() => {
    if (user) {
      closeLoginForm();
    } else if (errorCode > 399 && errorCode < 600) {
      setVerificationError(lang.wrong_username_or_password);
      return;
    }
  }, [user, user_token, errorCode]);

  //Provide recovery email and return user to LogIn Form
  const resetPassword = (e) => {
    e.preventDefault();
    if (values.recoverEmail === "") {
      setVerificationError(lang.fields_missing);
      return;
    }
    dispatch(recoverPassword({ email: values.recoverEmail }));
    setPasswordForgot(false);
    setVerificationError("");
    setPasswordRecovered(true);
    setValues(initialState);
  };

  //Set state for inputs
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  };

  return (
    <section className="loginModal">
      <div className="loginModalHeader">
        <MainButton
          image={xMark}
          handleClick={closeLoginModal}
          className="closeLoginModalButton"
        />
      </div>
      {passwordForgot ? (
        <section className="loginForm">
          <h3 className="modalLabel">{lang.recover_password}</h3>
          <div className="loginInputsContainer">
            <span className="singleInputContainer">
              <input
                className="loginInput"
                type="email"
                name="recoverEmail"
                onChange={handleChange}
                placeholder={lang.write_email_here}
              />
            </span>
            {verificationError && (
              <p className="errorMessageLabel">{verificationError}</p>
            )}
            <p className="passwordRecoveryLabel">{lang.password_sent_label}</p>
          </div>

          <MainButton
            handleClick={resetPassword}
            className="submitLoginForm"
            text={lang.next}
          />
        </section>
      ) : (
        <form className="loginForm" onSubmit={handleLogin} ref={modalRef}>
          {loginLoading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: `${modalRef.current.offsetWidth}px`,
                height: `${modalRef.current.offsetHeight}px`,
              }}
            >
              <Loading className={"loading loading-center"} />
            </div>
          ) : (
            <>
              <h3 className="modalLabel">{lang.sign_in}</h3>
              {passwordRecovered && (
                <p className="passwordRecoveredLabel">
                  {lang.check_email_for_reset}
                </p>
              )}
              <div className="loginInputsContainer">
                <span className="singleInputContainer">
                  <input
                    className="loginInput"
                    type="text"
                    name="email"
                    onChange={handleChange}
                    placeholder={lang.email_or_username}
                  />
                </span>
                <span className="singleInputContainer">
                  <input
                    className="loginInput"
                    type="password"
                    name="password"
                    onChange={handleChange}
                    placeholder={lang.password}
                  />
                  <button
                    className="forgotPasswordButton"
                    type="Button"
                    onClick={() => initiatePasswordForgot()}
                  >
                    {lang.forgot}
                  </button>
                </span>
                {verificationError && (
                  <p className="errorMessageLabel">{verificationError}</p>
                )}
              </div>
              <button type="submit" className="submitLoginForm">
                {lang.sign_in}
              </button>
            </>
          )}
        </form>
      )}
    </section>
  );
};

export default LoginModal;
