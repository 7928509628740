import "../assets/css/index.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
//Below is imitation data for API
// import { contactData } from "../utils/data";
//Assets and Images
import facebookIcon from "../assets/images/facebookIcon.svg";
import phoneIcon from "../assets/images/phoneIcon.svg";
import mailIcon from "../assets/images/mailIcon.svg";
import pinIcon from "../assets/images/pinIcon.svg";
import instagramIcon from "../assets/images/instagramIcon.svg";
import footerLogo from "../assets/images/footerlogo.png";
import abkLogo from "../assets/images/abkLogo.png";
const Footer = () => {
  const { email, facebook, phone, instagram, address } = useSelector(
    (store) => store.navBar
  );
  //Object to map for Footer contact information
  const contactData = [
    {
      icon: phoneIcon,
      text: phone,
    },
    {
      icon: mailIcon,
      text: email,
    },
    // {
    //   icon: pinIcon,
    //   text: address,
    // },
    {
      icon: instagramIcon,
      text: instagram,
    },
    {
      icon: facebookIcon,
      text: facebook,
    },
  ];

  return (
    <footer className="footer">
      <section className="contactsContainer">
        {/* {window.innerWidth > 1200 ? (
          <> */}
            <img src={footerLogo} alt="logo" style={{ width: 200, marginBottom: 30 }} />
            <div className="footer-row">
              <div className="footer-col-6">
                <p>
                  Creation of the Digital Guides Portal for the Georgian Mountain Guides Association (GMGA) was made possible through support provided by The USAID Economic Security Program, implemented by DAI Global.
                  <br></br>
                  The opinions expressed herein are those of the author(s) and do not necessarily reflect the views of the U.S. Agency for International Development.
                </p>
              </div>
              <div className="footer-col">
                <h5>contact us</h5>
                {contactData.slice(0, 2).map((contact, index) => {
                  const { icon, text } = contact;
                  return (
                    <a className="singleContact" key={index} href="#" style={{ marginBottom: 15 }}>
                      <div className="iconContainer">
                        <img src={icon} alt="Contact Icon" />
                      </div>
                      <p className="singleContactLabel">{text}</p>
                    </a>
                  );
                })}
              </div>
              <div className="footer-col footer-d-flex">
                <div style={{ width: 'fit-content' }}>
                  <h5>FOLLOW US</h5>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {contactData.slice(2, 4).map((contact, index) => {
                      const { icon, text } = contact;
                      return (
                        <a className="singleContact" key={index} href={text} style={{ marginRight: 15 }}>
                          <div className="iconContainer">
                            <img src={icon} alt="Contact Icon" />
                          </div>
                        </a>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          {/* </>
        ) : (
          <>
            {contactData.slice(0, 2).map((contact, index) => {
              const { icon, text } = contact;
              return (
                <a className="singleContact" key={index} href="#">
                  <div className="iconContainer">
                    <img src={icon} alt="Contact Icon" />
                  </div>
                  <p className="singleContactLabel">{text}</p>
                </a>
              );
            })}
            <div className="socialNetworkContactsContainer">
              {contactData.slice(2, 4).map((contact, index) => {
                const { icon, text } = contact;
                return (
                  <a
                    className="singleSocialMediaContact"
                    key={index}
                    href={text}
                  >
                    <div className="iconContainer">
                      <img src={icon} alt="Contact Icon" />
                    </div>
                  </a>
                );
              })}
            </div>
          </>
        )} */}
      </section>

      <div className="footer-bottom">
        <Link to={"#"} className="infoLabel" style={{marginBottom: 15}}>
          <p>Privacy Policy • Terms & Conditions</p>
        </Link>
        <Link to={"#"} className="infoLabel" style={{marginBottom: 15}}>
          <p>@ 2023 All Rights Reserved</p>
        </Link>
        <a target="_blank" className="infoLabel" style={{display: 'flex', alignItems: 'center', marginBottom: 15}}>
          <div style={{marginRight: 15}}>
            <img src={abkLogo} alt="Icon" style={{width: 30}} />
          </div>
          <p>Created by ABK communication</p>
        </a>
      </div>
    </footer>
  );
};
export default Footer;
