/** @format */

import { useEffect, useRef, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import Loading from "./loading";
import { lang } from "../utils/lang";
const ConfirmationModal = (props) => {
  const { handleYes, handleNo, handleRefClick, question } = props;
  const { deleteCommentisLoading, deletePostisLoading } = useSelector(
    (store) => store.newsFeed
  );

  const modalRef = useRef(null);

  const closeModalOnBackground = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      handleRefClick();
    }
  };

  useEffect(() => {
    document.addEventListener("click", closeModalOnBackground, true);
    return () => {
      document.removeEventListener("click", closeModalOnBackground, true);
    };
  }, []);

  return (
    <section className="standartConfirmationModalContainer">
      <span className="standartConfirmationModalBackground" />
      <div className="confirmationModal" ref={modalRef}>
        {deleteCommentisLoading || deletePostisLoading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: `${modalRef.current.offsetWidth}px`,
              height: `${modalRef.current.offsetHeight}px`,
            }}
          >
            <Loading className={"loading loading-center"} />
          </div>
        ) : (
          <>
            <p className="confirmationModalLabel">{question}</p>
            <div className="confirmationModalButtonsContainer">
              <button onClick={handleYes} className="confirmationModalButton">
                {lang.yes}
              </button>
              <button className="confirmationModalButton" onClick={handleNo}>
                {lang.no}
              </button>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default ConfirmationModal;
